import './LaporanPiutang.scss';
import PropTypes from 'prop-types'
import MasterLayout from '../../components/MasterLayout';
import Spinner from '../../components/Spinner';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { 
  Flex, 
  Select, 
  Space, 
  Input, 
  InputNumber,
  DatePicker,
  Button,
  Table,
  Modal,
  Form,
  Segmented,
  notification,
} from 'antd';
import {
  PlusOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  CheckCircleOutlined,
} from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import * as laporanPiutangApi from '../../stores/business/LaporanPiutangApi';
import * as customerApi from '../../stores/business/CustomerApi';

const Context = React.createContext({
  name: 'Default',
});

const { confirm } = Modal;

const Content = (tipe) => {
  const [api, contextHolder] = notification.useNotification();

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const openNotification = (message, description, type) => {
    if (type === "error") {
      api.error({
        duration: 3,
        message: message,
        description: description,
        placement: 'top',
      });
    } else if (type === "success") {
      api.success({
        duration: 3,
        message: message,
        description: description,
        placement: 'top',
      });
    }
  };

  const typeOption = [
    {value: 'belum-lunas', label: 'Belum Lunas'},
    {value: 'lunas', label: 'Lunas'},
  ];

  const [selectedType, setSelectedType] = useState('belum-lunas');
  const [filterOptionSelected, setFilterOptionSelected] = useState('noInvoice');
  const [filterSearchValue, setFilterSearchValue] = useState('');
  const [filterSearchDateValue, setFilterSearchDateValue] = useState('');
  const [tokoOpt, setTokoOpt] = useState([]);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [selectedData, setSelectedData] = useState({
    id: null,
    noInvoice: null,
    tanggalInvoice: null,
    namaToko: null,
    totalLusin: null,
    totalPiece: null,
    totalPrice: null,
  });
  
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
    sortField: "id",
    sortOrder: "descend",
  });

  function convertToIndonesianDate(dateString) {
    const months = [
        "Januari", "Februari", "Maret", "April", "Mei", "Juni",
        "Juli", "Agustus", "September", "Oktober", "November", "Desember"
    ];

    const date = new Date(dateString);
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
  }

  const tableColumnsBelumLunas = [
    {
      title: 'Indikator',
      dataIndex: 'indikator',
      key: 'indikator',
      sorter: false,
      render: (_, record) => (
        <div className={"round-icon " + record.indikator}></div>
      ),
    },
    {
      title: 'No. Invoice',
      dataIndex: 'noInvoice',
      key: 'noInvoice',
      sorter: true,
    },
    {
      title: 'Tanggal Invoice',
      dataIndex: 'tanggalInvoice',
      key: 'tanggalInvoice',
      sorter: true,
      render: (_, record) => (
        convertToIndonesianDate(record.tanggalInvoice)
      ),
    },
    {
      title: 'Nama Toko',
      dataIndex: 'namaToko',
      key: 'namaToko',
      sorter: true,
    },
    {
      title: 'Total Jumlah Lusin',
      dataIndex: 'totalLusin',
      key: 'totalLusin',
      sorter: true,
    },
    {
      title: 'Total Jumlah Piece',
      dataIndex: 'totalPiece',
      key: 'totalPiece',
      sorter: true,
    },
    {
      title: 'Sub Total',
      dataIndex: 'totalPrice',
      key: 'totalPrice',
      sorter: true,
      render: (_, record) => (
        new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(record.totalPrice).trim().split(",")[0]
      ),
    },
    {
      title: 'Jumlah Sub Total',
      dataIndex: 'totalHutangAccumulative',
      key: 'totalHutangAccumulative',
      sorter: true,
      render: (_, record) => (
        new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(record.totalHutangAccumulative).trim().split(",")[0]
      ),
    },
    {
      title: 'Source',
      dataIndex: 'source',
      key: 'source',
      sorter: true,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button shape="circle" icon={<CheckCircleOutlined />} onClick={() => {showRepaymentConfirm(record);}}/>
          {record.source === "Manual" && <Button shape="circle" icon={<EditOutlined />} onClick={() => {onClickEdit(record)}}/>}
          <Button shape="circle" icon={<DeleteOutlined />} onClick={() => {showDeleteConfirm(record);}}/>
        </Space>
      ),
    },
  ];

  const tableColumnsLunas = [
    {
      title: 'No. Invoice',
      dataIndex: 'noInvoice',
      key: 'noInvoice',
      sorter: true,
    },
    {
      title: 'Tanggal Invoice',
      dataIndex: 'tanggalInvoice',
      key: 'tanggalInvoice',
      sorter: true,
      render: (_, record) => (
        convertToIndonesianDate(record.tanggalInvoice)
      ),
    },
    {
      title: 'Nama Toko',
      dataIndex: 'namaToko',
      key: 'namaToko',
      sorter: true,
    },
    {
      title: 'Total Jumlah Lusin',
      dataIndex: 'totalLusin',
      key: 'totalLusin',
      sorter: true,
    },
    {
      title: 'Total Jumlah Piece',
      dataIndex: 'totalPiece',
      key: 'totalPiece',
      sorter: true,
    },
    {
      title: 'Sub Total',
      dataIndex: 'totalPrice',
      key: 'totalPrice',
      sorter: true,
      render: (_, record) => (
        new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(record.totalPrice).trim().split(",")[0]
      ),
    },
    {
      title: 'Source',
      dataIndex: 'source',
      key: 'source',
      sorter: true,
    },
  ];

  const filterOptions = [
    {value: 'noInvoice', label: 'No. Invoice'},
    {value: 'tanggalInvoice', label: 'Tanggal Invoice'},
    {value: 'namaToko', label: 'Nama Toko'},
    {value: 'source', label: 'Source'},
  ];

  const handleChangeOption = (value) => {
    setFilterOptionSelected(value);
    setFilterSearchDateValue(null);
    setFilterSearchValue('');
  };

  const handleChangeValue = (e) => {
    setFilterSearchValue(e.target.value);
  };

  const handleChangeDateValue = (date, dateString) => {
    setFilterSearchDateValue(date);
    setFilterSearchValue(dateString);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    var sortField = sorter.field;
    var sortOrder = sorter.order;
    setTableParams({
      pagination,
      filters,
      sortOrder: sortOrder,
      sortField: sortField,
    });
    
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  const resetData = () => {
    form.resetFields();
    form.setFieldsValue({
      id: null,
      noInvoice: null,
      tanggalInvoice: null,
      namaToko: null,
      totalLusin: null,
      totalPiece: null,
      totalPrice: null,
    });
    setSelectedData({
      id: null,
      noInvoice: null,
      tanggalInvoice: null,
      namaToko: null,
      totalLusin: null,
      totalPiece: null,
      totalPrice: null,
    });
  }

  const showDeleteConfirm = (record) => {
    confirm({
      title: 'Are you sure delete this item?',
      icon: <ExclamationCircleFilled />,
      content: 'You can\'t undo this operation when finished!',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      centered: true,
      onOk() {
        deleteData(record);
      },
    });
  };

  const deleteData = async (record) => {
    setLoading(true);
    const response = await dispatch(laporanPiutangApi.deleteItem(tipe, record.id));
    if (response.status === 200) {
      resetData();
      if (tableParams.pagination.current > 1) {
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            current: 1,
          }
        });
      } else {
        fetchData();
      }
      openNotification('Success', 'Success to delete item!', 'success');
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    }
    setLoading(false);
  };

  const showRepaymentConfirm = (record) => {
    confirm({
      title: 'Apakah Anda yakin transaksi ini sudah Lunas?',
      icon: <ExclamationCircleFilled />,
      content: 'Ini akan memindahkan datamu ke Tab Lunas. Anda tidak dapat membatalkan aksi ini!',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      centered: true,
      onOk() {
        repaymentData(record);
      },
    });
  };

  const onClickEdit = (record) => {
    setSelectedData(record);
    form.setFieldsValue({
      ...record,
      totalPrice: new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(record.totalPrice).replace('Rp', '').trim().split(",")[0],
      tanggalInvoice: dayjs(record.tanggalInvoice)
    });

    setIsOpenModal(true);
  };

  const onCancel = () => {
    resetData();

    setIsOpenModal(false);
  };

  const repaymentData = async (record) => {
    setLoading(true);
    const response = await dispatch(laporanPiutangApi.repayment(tipe, record.id));
    if (response.status === 200) {
      resetData();
      if (tableParams.pagination.current > 1) {
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            current: 1,
          }
        });
      } else {
        fetchData();
      }
      openNotification('Success', 'Sukses mengubah statu transaksi menjadi lunas!', 'success');
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    }
    setLoading(false);
  };

  const fetchData = async () => {
    setLoading(true);

    var sortField = tableParams.sortField === undefined ? "id" : tableParams.sortField;
    var sortOrder = tableParams.sortOrder === "ascend" ? "asc" : "desc"

    if (tableParams.sortOrder === undefined || tableParams.sortOrder === null) {
      sortOrder = "desc";
      sortField = "id";
    }

    const response = await dispatch(laporanPiutangApi.getPage(tipe, selectedType,{
      "page": tableParams.pagination.current,
      "size": tableParams.pagination.pageSize,
      "sort": [
        sortField + " " + sortOrder,
      ],
      "search": [
        {
          field: filterOptionSelected,
          value: filterSearchValue
        }
      ]
    }));
    if (response.status === 200) {
      setData(response.data.contents);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: response.data.totalData,
        }
      });
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
    setLoading(false);
  };

  const fetchToko = async () => {
    const response = await dispatch(customerApi.getTokoList());
    if (response.status === 200) {
      setTokoOpt(response.data);
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
  };

  const submit = async (values) => {
    setIsOpenModal(false);
    values = {
      ...values,
      id: selectedData.id,
      totalPrice: values.totalPrice === null || values.totalPrice === "" ? null : parseInt(values.totalPrice.replaceAll('.', '')),
      tanggalInvoice: values.tanggalInvoice?.format('YYYY-MM-DD'),
    }
    const response = await dispatch(laporanPiutangApi.post(tipe, values));
    if (response.status === 200) {
      if (tableParams.pagination.current > 1) {
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            current: 1,
          }
        });
      } else {
        fetchData();
      }
      openNotification('Success', selectedData.id === null ? 'Success to add data!' : 'Success to update data!', 'success');
      resetData();
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
      setIsOpenModal(true);
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
      setIsOpenModal(true);
    }
  };

  useEffect(() => {
    fetchData();
  }, [
    filterOptionSelected,
    filterSearchValue,
    selectedType,
    tableParams.pagination?.current,
    tableParams.pagination?.pageSize,
    tableParams?.sortOrder,
    tableParams?.sortField,
  ]);

  useEffect(() => {
    fetchToko();
  }, []);

  return (
    <Context.Provider value={true}>
      {contextHolder}
      <Segmented 
        options={typeOption} 
        value={selectedType} 
        size='large'
        onChange={setSelectedType} />
      
      <Flex gap="middle" vertical={false}>
        <Space>
          <Select
            size="large"
            value={filterOptionSelected}
            onChange={handleChangeOption}
            style={{ width: 250 }}
            options={filterOptions}
          />
          {
            filterOptionSelected !== "tanggalInvoice" && 
            <Input 
              size="large"
              style={{ width: 300 }}
              onChange={handleChangeValue}
              value={filterSearchValue} />
          }
          {
            filterOptionSelected === "tanggalInvoice" &&
            <DatePicker 
              size='large'
              style={{width: 300}}
              onChange={handleChangeDateValue}
              value={filterSearchDateValue}
              placeholder=''
            />
          }
        </Space>
        <Space align='end'
          style={{ flexBasis: '100%', flexDirection: 'column-reverse' }}
          >
          {selectedType === "belum-lunas" && 
            <Button 
              size="large" 
              type="primary"
              icon={<PlusOutlined />}
              style={{ width: 200 }}
              iconPosition='start'
              onClick={() => {
                setIsOpenModal(true);
              }}
            >
              Add Piutang
            </Button>
          }
        </Space>
      </Flex>

      {selectedType === "belum-lunas" &&
        <div class="legend">
          <div class="legend-title">LEGENDA</div>
          <div class="legend-line"></div>
      
          <div class="legend-item">
            <div class="circle green-indicator"></div> 
            <div>0 Hari - 45 Hari</div>
          </div>
          <div class="legend-item">
            <div class="circle yellow-indicator"></div> 
            <div>46 Hari - 60 Hari</div>
          </div>
          <div class="legend-item">
            <div class="circle orange-indicator"></div> 
            <div>61 Hari - 90 Hari</div>
          </div>
          <div class="legend-item">
            <div class="circle black-indicator"></div> 
            <div>91 Hari - ∞ Hari</div>
          </div>
        </div>
      }

      <Flex gap="middle" vertical style={{marginTop: '24px'}}>
        <Table
          columns={selectedType === "belum-lunas" ? tableColumnsBelumLunas : tableColumnsLunas}
          rowKey={(record) => record.id}
          dataSource={data}
          pagination={tableParams.pagination}
          loading={{
            spinning: loading,
            indicator: <Spinner className="spinner"/>
          }}
          onChange={handleTableChange}
        />
      </Flex>

      <Modal
        title={selectedData.id === null ? "Add Laporan Piutang" : "Edit Laporan Piutang"}
        open={isOpenModal}
        width={900}
        closable={false}
        footer={[]}
      >
        <Form
          form={form}
          name="form"
          className="form"
          layout="vertical"
          autoComplete="off"
          autoFocus={true}
          onFinish={submit}
        >

          <Form.Item
            layout="vertical"
            label="Nomor Invoice"
            name="noInvoice"
            style={{marginTop: '16px'}}
            rules={[
              {
                required: true,
                message: 'Please input nomor invoice',
              },
            ]}
          >
            <Input size='large' maxLength={50} style={{width: '100%'}} 
              disabled={selectedData.id !== null}
              placeholder="Nomor Invoice"/>
          </Form.Item>

          <Space>
            <Form.Item
              layout="vertical"
              label="Tanggal Invoice"
              name="tanggalInvoice"
              style={{width: '422px'}}
              rules={[
                {
                  required: true,
                  message: 'Pilih tanggal invoice',
                },
              ]}
            >
              <DatePicker 
                disabled={selectedData.id !== null}
                size='large'
                style={{width: '100%'}}
              />
            </Form.Item>
            <Form.Item
              layout="vertical"
              label="Nama Toko"
              name="namaToko"
              style={{width: '422px'}}
              rules={[
                {
                  required: true,
                  message: 'Pilih nama toko',
                },
              ]}
            >
              <Select 
                disabled={selectedData.id !== null}
                showSearch
                placeholder="Pilih nama toko"
                options={tokoOpt} 
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                optionLabelProp="label"
                size="large" />
            </Form.Item>
          </Space>

          <Space>
            <Form.Item
              layout="vertical"
              label="Jumlah Total Lusin"
              name="totalLusin"
              style={{width: '422px'}}
              rules={[
                {
                  required: true,
                  message: 'Please input jumlah total lusin',
                },
              ]}
            >
              <InputNumber size='large' min={0} style={{width: '100%'}} 
                disabled={selectedData.id !== null}
                placeholder="Jumlah total lusin"/>
            </Form.Item>
            <Form.Item
              layout="vertical"
              label="Jumlah Total Piece"
              name="totalPiece"
              style={{width: '422px'}}
              rules={[
                {
                  required: true,
                  message: 'Please input jumlah total piece',
                },
              ]}
            >
              <InputNumber size='large' min={0} style={{width: '100%'}} 
                disabled={selectedData.id !== null}
                placeholder="Jumlah total piece"/>
            </Form.Item>
          </Space>

          <Form.Item
            layout="vertical"
            label="Sub Total"
            name="totalPrice"
            rules={[
              {
                required: true,
                message: 'Please input sub total',
              },
            ]}
          >
            <Input size='large' addonBefore="Rp" min={0} style={{width: '100%'}} 
              placeholder="Sub total" onChange={(e) => {
                let value = e.target.value.replace(/\D/g, '');
                let formattedNumber = new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(value).replace('Rp', '').trim().split(",")[0];
                form.setFieldValue('totalPrice', formattedNumber);
              }} />
          </Form.Item>
          <Space
            align='end'
            style={{ flexBasis: '100%', flexDirection: 'column-reverse', width: '100%', marginTop: '28px', }}
          >
            <Form.Item>
              <Button htmlType="button" size="large" style={{marginRight: '8px'}} onClick={onCancel}> 
                Cancel
              </Button>
              <Button type="primary" htmlType="submit" size="large">
                {selectedData.id === null ? "Add" : "Edit"}
              </Button>
            </Form.Item>
          </Space>
        </Form>
      </Modal>
    </Context.Provider>
  );
}

const LaporanPiutang = ({
  tipe,
  pageName
}) => {
  return (
    <MasterLayout
      pageName={"Laporan Piutang " + pageName}
      activeMenu={"laporan-piutang-" + tipe} 
      activeParent="laporan-piutang"
      content={Content(tipe)}
    />
  )
}

LaporanPiutang.propTypes = {
  tipe: PropTypes.string,
  pageName: PropTypes.string,
};

export default LaporanPiutang;