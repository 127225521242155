import { CallRestApiSecure } from '../../libs/Api';

export const getPage = (tipe, status, payload) => async () => {
  const BASE_URL = process.env.REACT_APP_API_URL;

  try {
    const response = await CallRestApiSecure('post', BASE_URL, 'v1/laporan-piutang/' + tipe + '/' + status + '/page', payload, {}, {}, 'application/json');
    return JSON.parse(response);
  } catch (err) {
    return JSON.parse(err.response.data);
  }
};

export const deleteItem = (tipe, id) => async () => {
  const BASE_URL = process.env.REACT_APP_API_URL;

  try {
    const response = await CallRestApiSecure('delete', BASE_URL, 'v1/laporan-piutang/' + tipe + '/delete/' + id, {}, {}, {}, 'application/json');
    return JSON.parse(response);
  } catch (err) {
    return JSON.parse(err.response.data);
  }
};

export const repayment = (tipe, id) => async () => {
  const BASE_URL = process.env.REACT_APP_API_URL;

  try {
    const response = await CallRestApiSecure('get', BASE_URL, 'v1/laporan-piutang/' + tipe + '/repayment/' + id, {}, {}, {}, 'application/json');
    return JSON.parse(response);
  } catch (err) {
    return JSON.parse(err.response.data);
  }
};

export const post = (tipe, payload) => async () => {
  const BASE_URL = process.env.REACT_APP_API_URL;

  try {
    const response = await CallRestApiSecure('post', BASE_URL, 'v1/laporan-piutang/' + tipe + '/post', payload, {}, {}, 'application/json');
    return JSON.parse(response);
  } catch (err) {
    return JSON.parse(err.response.data);
  }
};

export const getPageAkumulasiPiutang = (payload) => async () => {
  const BASE_URL = process.env.REACT_APP_API_URL;

  try {
    const response = await CallRestApiSecure('post', BASE_URL, 'v1/laporan-piutang/akumulasi-piutang/page', payload, {}, {}, 'application/json');
    return JSON.parse(response);
  } catch (err) {
    return JSON.parse(err.response.data);
  }
};