import React, { useEffect, useState } from 'react';
import './User.scss';
import MasterLayout from '../../components/MasterLayout';
import Spinner from '../../components/Spinner';
import { 
  Flex, 
  Select, 
  Space, 
  Input, 
  Button,
  Table,
  Modal,
  Form,
  notification,
} from 'antd';
import {
  PlusOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
} from '@ant-design/icons';
import * as userApi from '../../stores/business/UserApi';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const Context = React.createContext({
  name: 'Default',
});

const { confirm } = Modal;

const Content = () => {
  const [api, contextHolder] = notification.useNotification();
  const [filterOptionSelected, setFilterOptionSelected] = useState('fullName');
  const [filterSearchValue, setFilterSearchValue] = useState('');
  const [isOpenModalAdd, setIsOpenModalAdd] = useState(false);
  const [form] = Form.useForm();
  const [formEdit] = Form.useForm();
  const nav = useNavigate();
  const dispatch = useDispatch();

  const tableColumns = [
    {
      title: 'Full Name',
      dataIndex: 'fullName',
      key: 'fullName',
      sorter: true,
    },
    {
      title: 'Username',
      dataIndex: 'userName',
      key: 'userName',
      sorter: true,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button shape="circle" icon={<EditOutlined />} onClick={() => {setEditData(record);formEdit.setFieldsValue(record);setIsOpenModalEdit(true)}}/>
          <Button shape="circle" icon={<DeleteOutlined />} onClick={() => {showDeleteConfirm(record);}}/>
        </Space>
      ),
    },
  ];

  const openNotification = (message, description, type) => {
    if (type === "error") {
      api.error({
        duration: 3,
        message: message,
        description: description,
        placement: 'top',
      });
    } else if (type === "success") {
      api.success({
        duration: 3,
        message: message,
        description: description,
        placement: 'top',
      });
    }
  };

  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
    sortField: "createdAt",
    sortOrder: "descend",
  });

  const filterOptions = [
    {value: 'fullName', label: 'Full Name'},
    {value: 'userName', label: 'Username'},
  ];

  const handleChangeOption = (value) => {
    setFilterOptionSelected(value);
  };

  const handleChangeValue = (e) => {
    setFilterSearchValue(e.target.value);
  };

  const fetchData = async () => {
    setLoading(true);

    var sortField = tableParams.sortField === undefined ? "createdAt" : tableParams.sortField;
    var sortOrder = tableParams.sortOrder === "ascend" ? "asc" : "desc";

    if (tableParams.sortOrder === undefined || tableParams.sortOrder === null) {
      sortOrder = "desc";
      sortField = "createdAt";
    }

    const response = await dispatch(userApi.getUserPage({
      "page": tableParams.pagination.current,
      "size": tableParams.pagination.pageSize,
      "sort": [
        sortField + " " + sortOrder
      ],
      "search": [
        {
          "field": filterOptionSelected,
          "value": filterSearchValue
        }
      ]
    }));
    if (response.status === 200) {
      setData(response.data.contents);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: response.data.totalData,
        }
      });
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=master/user")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    }
    setLoading(false);
  };

  const deleteData = async (record) => {
    setLoading(true);
    const response = await dispatch(userApi.deleteUser(record.userId));
    if (response.status === 200) {
      fetchData();
      openNotification('Success', 'Success to delete user!', 'success');
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=master/role")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [
    filterOptionSelected,
    filterSearchValue,
    tableParams.pagination?.current,
    tableParams.pagination?.pageSize,
    tableParams?.sortOrder,
    tableParams?.sortField,
  ]);

  const handleTableChange = (pagination, filters, sorter) => {
    var sortField = sorter.field;
    var sortOrder = sorter.order;
    setTableParams({
      pagination,
      filters,
      sortOrder: sortOrder,
      sortField: sortField,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  const openModalAdd = () => {
    setIsOpenModalAdd(true);
  }

  const onFinish = async (values) => {
    setIsOpenModalAdd(false);
    const response = await dispatch(userApi.createUser({
      fullName: values.fullNameInsert,
      userName: values.userNameInsert,
      password: values.passwordInsert,
      confirmPassword: values.confirmPasswordInsert,
    }));
    if (response.status === 200) {
      openNotification('Success', 'Success to add new user!', 'success');
      fetchData();
      form.resetFields();
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=master/user")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
      setIsOpenModalAdd(true);
    }
  };

  const onFinishEdit = async (values) => {
    setIsOpenModalEdit(false);
    const response = await dispatch(userApi.editUser({
      fullName: values.fullName,
      password: values.password,
      confirmPassword: values.confirmPassword,
    }, editData.userId));
    if (response.status === 200) {
      openNotification('Success', 'Success to edit user!', 'success');
      fetchData();
      form.resetFields();
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=master/user")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
      setIsOpenModalEdit(true);
    }
  };

  const onCancel = () => {
    form.resetFields();
    setIsOpenModalAdd(false);
    setIsOpenModalEdit(false);
  };

  const showDeleteConfirm = (record) => {
    confirm({
      title: 'Are you sure delete this user?',
      icon: <ExclamationCircleFilled />,
      content: 'You can\'t undo this operation when finished!',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      centered: true,
      onOk() {
        deleteData(record);
      },
    });
  };

  const [isOpenModalEdit, setIsOpenModalEdit] = useState(false);
  const [editData, setEditData] = useState();

  return (
    <Context.Provider value={true}>
      {contextHolder}
      <Flex gap="middle" vertical={false}>
        <Space>
          <Select
            size="large"
            defaultValue={filterOptionSelected}
            onChange={handleChangeOption}
            style={{ width: 200 }}
            options={filterOptions}
          />
          <Input 
            size="large"
            style={{ width: 300 }}
            onChange={handleChangeValue}
            value={filterSearchValue} />
        </Space>
        <Space align='end'
          style={{ flexBasis: '100%', flexDirection: 'column-reverse' }}
          >
          <Button 
            size="large" 
            type="primary"
            icon={<PlusOutlined />}
            style={{ width: 200 }}
            iconPosition='start'
            onClick={openModalAdd}
          >
            Add User
          </Button>
        </Space>
      </Flex>
      <Flex gap="middle" vertical style={{marginTop: '24px'}}>
        <Table
          columns={tableColumns}
          rowKey={(record) => record.userId}
          dataSource={data}
          pagination={tableParams.pagination}
          loading={{
            spinning: loading,
            indicator: <Spinner className="spinner"/>
          }}
          onChange={handleTableChange}
        />
        <Modal
          title="Add New User"
          open={isOpenModalAdd}
          width={700}
          closable={false}
          footer={[]}
        >
          <Form
            form={form}
            name="add-user-form"
            className="add-user-form"
            layout="vertical"
            autoComplete="off"
            autoFocus={true}
            onFinish={onFinish}
          >
            <Space>
              <Form.Item
                layout="vertical"
                label="Full Name"
                name="fullNameInsert"
                style={{width: '443px'}}
                rules={[
                  {
                    required: true,
                    message: 'Please input your full name!',
                  },
                  {
                    max: 50,
                    message: 'Maximum 50 Characters',
                  },
                ]}
              >
                <Input size="large"/>
              </Form.Item>
              <Form.Item
                layout="vertical"
                label="Username"
                name="userNameInsert"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Please input your username!',
                  },
                  {
                    max: 50,
                    message: 'Maximum 50 Characters',
                  },
                ]}
              >
                <Input size="large" maxLength={50}/>
              </Form.Item>
            </Space>
            
            <Form.Item
              layout="vertical"
              label="Password"
              name="passwordInsert"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
                {
                  pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.,])[A-Za-z\d@$!%*?&.,]{8,20}$/,
                  message: 'password must contains at least 1 uppercase, 1 lowercase, 1 digit, 1 symbol, and minimum length 8 characters',
                },
              ]}
            >
              <Input.Password  size="large"/>
            </Form.Item>

            <Form.Item
              layout="vertical"
              label="Re-Type Password"
              dependencies={['passwordInsert']}
              name="confirmPasswordInsert"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Please input your confirm password!',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    console.log(value);
                    console.log(getFieldValue('passwordInsert'));
                    if (!value || getFieldValue('passwordInsert') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('The new password that you entered do not match!'));
                  },
                }),
                {
                  pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.,])[A-Za-z\d@$!%*?&.,]{8,20}$/,
                  message: 'password must contains at least 1 uppercase, 1 lowercase, 1 digit, 1 symbol, and minimum length 8 characters',
                },
              ]}
            >
              <Input.Password  size="large"/>
            </Form.Item>
            <Space
              align='end'
              style={{ flexBasis: '100%', flexDirection: 'column-reverse', width: '100%', marginTop: '28px', }}
            >
              <Form.Item>
                <Button htmlType="button" size="large" style={{marginRight: '8px'}} onClick={onCancel}> 
                  Cancel
                </Button>
                <Button type="primary" htmlType="submit" size="large">
                  Add
                </Button>
              </Form.Item>
            </Space>
          </Form>
        </Modal>
        <Modal
          title="Edit User"
          open={isOpenModalEdit}
          width={700}
          closable={false}
          footer={[]}
        >
          <Form
            form={formEdit}
            initialValues={editData}
            name="edit-user-form"
            className="edit-user-form"
            layout="vertical"
            autoComplete="off"
            autoFocus={true}
            onFinish={onFinishEdit}
          >
            <Space>
              <Form.Item
                layout="vertical"
                label="Full Name"
                name="fullName"
                style={{width: '443px'}}
                rules={[
                  {
                    required: true,
                    message: 'Please input your full name!',
                  },
                ]}
              >
                <Input size="large" />
              </Form.Item>
              <Form.Item
                layout="vertical"
                label="Username"
                name="userName"
                hasFeedback
              >
                <Input size="large" disabled/>
              </Form.Item>
            </Space>
            
            <Form.Item
              layout="vertical"
              label="Password"
              name="password"
              hasFeedback
              rules={[
                {
                  required: false,
                  message: 'Please input your password!',
                },
                {
                  pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.,])[A-Za-z\d@$!%*?&.,]{8,20}$/,
                  message: 'password must contains at least 1 uppercase, 1 lowercase, 1 digit, 1 symbol, and minimum length 8 characters',
                },
              ]}
            >
              <Input.Password  size="large"/>
            </Form.Item>

            <Form.Item
              layout="vertical"
              label="Re-Type Password"
              dependencies={['password']}
              name="confirmPassword"
              hasFeedback
              rules={[
                ({ getFieldValue }) => ({
                  required: !!getFieldValue('password'),
                  message: 'Please input your confirm password!',
                }),
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('The new password that you entered do not match!'));
                  },
                }),
                {
                  pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.,])[A-Za-z\d@$!%*?&.,]{8,20}$/,
                  message: 'password must contains at least 1 uppercase, 1 lowercase, 1 digit, 1 symbol, and minimum length 8 characters',
                },
              ]}
            >
              <Input.Password  size="large"/>
            </Form.Item>
            <Space
              align='end'
              style={{ flexBasis: '100%', flexDirection: 'column-reverse', width: '100%', marginTop: '28px', }}
            >
              <Form.Item>
                <Button htmlType="button" size="large" style={{marginRight: '8px'}} onClick={onCancel}> 
                  Cancel
                </Button>
                <Button type="primary" htmlType="submit" size="large">
                  Edit
                </Button>
              </Form.Item>
            </Space>
          </Form>
        </Modal>
      </Flex>
    </Context.Provider>
  )
}

const User = () => {
  return (
    <MasterLayout
      pageName="User"
      activeMenu="master-user" 
      activeParent="master"
      content={Content()}
    />
  )
}

export default User;