import React, { useEffect, useState } from 'react';
import './Customer.scss';
import MasterLayout from '../../components/MasterLayout';
import Spinner from '../../components/Spinner';
import { 
  Flex, 
  Select, 
  Space, 
  Input, 
  Button,
  Table,
  Modal,
  Form,
  notification,
} from 'antd';
import {
  PlusOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
} from '@ant-design/icons';
import * as customerApi from '../../stores/business/CustomerApi';
import * as provinceApi from '../../stores/business/ProvinceApi';
import * as cityApi from '../../stores/business/CityApi';
import * as expeditionApi from '../../stores/business/ExpeditionApi';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const Context = React.createContext({
  name: 'Default',
});

const { confirm } = Modal;

const Content = () => {
  const [api, contextHolder] = notification.useNotification();
  const [filterOptionSelected, setFilterOptionSelected] = useState('customerName');
  const [filterSearchValue, setFilterSearchValue] = useState('');
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [data, setData] = useState();
  const [customerExpeditionData, setCustomerExpeditionData] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingExpedition, setLoadingExpedition] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
    sortField: "createdAt",
    sortOrder: "descend",
  });
  const [tableParamsExpedition, setTableParamsExpedition] = useState({
    pagination: {
      current: 1,
      pageSize: 5,
    },
    sortField: "createdAt",
    sortOrder: "descend",
  });
  const [selectedData, setSelectedData] = useState({
    id: null,
    storeName: "",
    customerName: "",
    address: "",
    province: null,
    phoneNumber: "",
    homePhoneNumber: "",
  });
  const [provinceList, setProvinceList] = useState();
  const [cityList, setCityList] = useState();
  const [capitalList, setCapitalList] = useState();
  const [capitalIdList, setCapitalIdList] = useState();
  const [expeditionList, setExpeditionList] = useState();
  const [selectedExpedition, setSelectedExpedition] = useState();

  const [form] = Form.useForm();
  const nav = useNavigate();
  const dispatch = useDispatch();

  const openNotification = (message, description, type) => {
    if (type === "error") {
      api.error({
        duration: 3,
        message: message,
        description: description,
        placement: 'top',
      });
    } else if (type === "success") {
      api.success({
        duration: 3,
        message: message,
        description: description,
        placement: 'top',
      });
    }
  };

  const tableColumns = [
    {
      title: 'ID Pelanggan',
      dataIndex: 'customerName',
      key: 'customerName',
      width: '250px',
      sorter: true,
    },
    {
      title: 'Nama Toko',
      dataIndex: 'storeName',
      key: 'storeName',
      sorter: true,
    },
    {
      title: 'No. Handphone',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      sorter: false,
    },
    {
      title: 'No. Telephone',
      dataIndex: 'homePhoneNumber',
      key: 'homePhoneNumber',
      sorter: false,
    },
    {
      title: 'Alamat',
      dataIndex: 'address',
      key: 'address',
      sorter: false,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button shape="circle" icon={<EditOutlined />} onClick={() => {onClickEdit(record)}}/>
          <Button shape="circle" icon={<DeleteOutlined />} onClick={() => {showDeleteCustomerConfirm(record);}}/>
        </Space>
      ),
    },
  ];

  const expeditionColumns = [
    {
      title: 'Expedisi',
      dataIndex: 'expeditionName',
      key: 'expeditionName',
      sorter: false,
      width: '70%',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button shape="circle" icon={<DeleteOutlined />} onClick={() => {showDeleteExpeditionConfirm(record);}}/>
        </Space>
      ),
    },
  ];


  const filterOptions = [
    {value: 'customerName', label: 'ID Pelanggan'},
    {value: 'storeName', label: 'Nama Toko'},
    {value: 'phoneNumber', label: 'No. Handphone'},
    {value: 'address', label: 'Address'},
  ];

  const handleChangeOption = (value) => {
    setFilterOptionSelected(value);
  };

  const handleChangeValue = (e) => {
    setFilterSearchValue(e.target.value);
  };

  const fetchProvince = async () => {
    const response = await dispatch(provinceApi.getProvince());
    if (response.status === 200) {
      setProvinceList(response.data);
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=master/customer")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    }
  }

  const fetchExpedition = async () => {
    let customerId = selectedData.id === null ? 0 : selectedData.id;
    const response = await dispatch(expeditionApi.getExpeditionNotAssignedCustomer(customerId));
    if (response.status === 200) {
      setExpeditionList(response.data);
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=master/customer")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    }
  }

  const fetchData = async () => {
    setLoading(true);

    var sortField = tableParams.sortField === undefined ? "createdAt" : tableParams.sortField;
    var sortOrder = tableParams.sortOrder === "ascend" ? "asc" : "desc";

    if (tableParams.sortOrder === undefined || tableParams.sortOrder === null) {
      sortOrder = "desc";
      sortField = "createdAt";
    }

    const response = await dispatch(customerApi.getCustomerPage({
      "page": tableParams.pagination.current,
      "size": tableParams.pagination.pageSize,
      "sort": [
        sortField + " " + sortOrder
      ],
      "search": [
        {
          "field": filterOptionSelected,
          "value": filterSearchValue
        }
      ]
    }));
    if (response.status === 200) {
      setData(response.data.contents);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: response.data.totalData,
        }
      });
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=master/customer")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    }
    setLoading(false);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    var sortField = sorter.field;
    var sortOrder = sorter.order;
    setTableParams({
      pagination,
      filters,
      sortOrder: sortOrder,
      sortField: sortField,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  const handleTableExpeditionChange = (pagination, filters, sorter) => {
    var sortField = sorter.field;
    var sortOrder = sorter.order;
    setTableParamsExpedition({
      pagination,
      filters,
      sortOrder: sortOrder,
      sortField: sortField,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParamsExpedition.pagination?.pageSize) {
      setCustomerExpeditionData([]);
    }
  };

  const initCustomer = async () => {
    form.setFieldsValue({
      id: null,
      storeName: "",
      customerName: "",
      address: "",
      province: null,
      phoneNumber: "",
      homePhoneNumber: "",
    });
    setSelectedData({
      id: null,
      storeName: "",
      customerName: "",
      address: "",
      province: null,
      phoneNumber: "",
      homePhoneNumber: "",
    });
    const response = await dispatch(customerApi.initCustomer());
    if (response.status === 200) {
      setSelectedData(response.data);
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=master/customer")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    }
  }

  const onFinish = async (values) => {
    setIsOpenModal(false);
    const response = await dispatch(customerApi.updateCustomer(selectedData.id, {
      storeName: values.storeName,
      customerName: values.customerName,
      address: values.address,
      province: values.province,
      city: values.city,
      phoneNumber: values.phoneNumber,
      homePhoneNumber: values.homePhoneNumber,
    }));
    if (response.status === 200) {
      let message = selectedData.isActive === true ? 'Success to edit customer!' : 'Success to add new customer!';
      openNotification('Success', message, 'success');
      fetchData();
      form.setFieldsValue({
        id: null,
        storeName: "",
        customerName: "",
        address: "",
        province: null,
      });
      setSelectedData({
        id: null,
        storeName: "",
        customerName: "",
        address: "",
        province: null,
      });
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=master/role")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
      setIsOpenModal(true);
    }
  };

  const onCancel = () => {
    form.resetFields();
    form.setFieldsValue({
      id: null,
      storeName: "",
      customerName: "",
      address: "",
      province: null,
    });
    setSelectedData({
      id: null,
      storeName: "",
      customerName: "",
      address: "",
      province: null,
    });
    setIsOpenModal(false);

    fetchCityDropdown("city");
    fetchCityDropdown("capital");
    fetchCityDropdown("capital-id");
  };

  const onClickEdit = (record) => {
    record.province = record.province+"";
    record.city = record.city !== null ? record.city +"" : null;
    setSelectedData(record);
    form.setFieldsValue(record);

    fetchCityDropdown("city");
    fetchCityDropdown("capital");
    fetchCityDropdown("capital-id");
  }

  const showDeleteCustomerConfirm = (record) => {
    confirm({
      title: 'Are you sure delete this customer?',
      icon: <ExclamationCircleFilled />,
      content: 'You can\'t undo this operation when finished!',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      centered: true,
      onOk() {
        deleteDataCustomer(record);
      },
    });
  };

  const deleteDataCustomer = async (record) => {
    setLoading(true);
    const response = await dispatch(customerApi.deleteCustomer(record.id));
    if (response.status === 200) {
      fetchData();
      openNotification('Success', 'Success to delete customer!', 'success');
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=master/role")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    }
    setLoading(false);
  };

  const showDeleteExpeditionConfirm = (record) => {
    confirm({
      title: 'Are you sure delete this expedition?',
      icon: <ExclamationCircleFilled />,
      content: 'You can\'t undo this operation when finished!',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      centered: true,
      onOk() {
        deleteDataExpedition(record);
      },
    });
  };

  const deleteDataExpedition = async (record) => {
    setLoadingExpedition(true);
    const response = await dispatch(customerApi.removeExpedition({
      customerId: selectedData.id,
      expeditionCodes: [record.expeditionCode],
    }));
    if (response.status === 200) {
      fetchExpedition();
      if (tableParamsExpedition.pagination.current > 1) {
        setTableParamsExpedition({
          ...tableParamsExpedition,
          pagination: {
            ...tableParamsExpedition.pagination,
            current: 1,
          }
        });
      } else {
        fetchCustomerExpedition();
      }
      openNotification('Success', 'Success to delete expedition!', 'success');
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    }
    setLoadingExpedition(false);
  };

  const fetchCityDropdown = async (type) => {
    let provinceId = form.getFieldValue('province');
    if (provinceId === null || provinceId === undefined || provinceId === "") {
      provinceId = 0;
    }
    const response = await dispatch(cityApi.getDropdown(type, provinceId));
    if (response.status === 200) {
      if (type === "city") {
        setCityList(response.data);
      } else if (type === "capital") {
        setCapitalList(response.data);
      } else if (type === "capital-id") {
        setCapitalIdList(response.data);
      }
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    }
  };

  const fetchCustomerExpedition = async () => {
    setLoadingExpedition(true);

    var sortField = tableParamsExpedition.sortField === undefined ? "createdAt" : tableParamsExpedition.sortField;
    var sortOrder = tableParamsExpedition.sortOrder === "ascend" ? "asc" : "desc"

    const response = await dispatch(customerApi.getExpedition(selectedData.id ? selectedData.id : 0, {
      "page": tableParamsExpedition.pagination.current,
      "size": tableParamsExpedition.pagination.pageSize,
      "sort": [
        sortField + " " + sortOrder
      ],
    }));
    if (response.status === 200) {
      setCustomerExpeditionData(response.data.contents);
      setTableParamsExpedition({
        ...tableParamsExpedition,
        pagination: {
          ...tableParamsExpedition.pagination,
          total: response.data.totalData,
        }
      });
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=master/customer")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    }
    setLoadingExpedition(false);
  };

  const reassignProvince = async (cityId) => {
    const response = await dispatch(cityApi.getProvinceIdByCityId(cityId));
    if (response.status === 200) {
      form.setFieldValue('province', response.data + '');
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    }
  };

  const addExpedition = async (record) => {
    setLoadingExpedition(true);
    const response = await dispatch(customerApi.addExpedition({
      customerId: selectedData.id,
      expeditionCodes: [selectedExpedition],
    }));
    if (response.status === 200) {
      fetchExpedition();
      setSelectedExpedition('');
      if (tableParamsExpedition.pagination.current > 1) {
        setTableParamsExpedition({
          ...tableParamsExpedition,
          pagination: {
            ...tableParamsExpedition.pagination,
            current: 1,
          }
        });
      } else {
        fetchCustomerExpedition();
      }
      openNotification('Success', 'Success to add expedition!', 'success');
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=master/customer")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    }
    setLoadingExpedition(false);
  };

  useEffect(() => {
    fetchData();
  }, [
    filterOptionSelected,
    filterSearchValue,
    tableParams.pagination?.current,
    tableParams.pagination?.pageSize,
    tableParams?.sortOrder,
    tableParams?.sortField,
  ]);

  useEffect(() => {
    fetchCustomerExpedition();
  }, [
    tableParamsExpedition.pagination?.current,
    tableParamsExpedition.pagination?.pageSize,
    tableParamsExpedition?.sortOrder,
    tableParamsExpedition?.sortField,
  ]);

  useEffect(() => {
    if (selectedData.id !== null) {
      setIsOpenModal(true);
      fetchCustomerExpedition();
    }
  }, [
    selectedData
  ]);

  useEffect(() => {
    fetchProvince();
    fetchExpedition();
    fetchCityDropdown("city");
    fetchCityDropdown("capital");
    fetchCityDropdown("capital-id");
  }, []);

  return (
    <Context.Provider value={true}>
      {contextHolder}
      <Flex gap="middle" vertical={false}>
        <Space>
          <Select
            size="large"
            defaultValue={filterOptionSelected}
            onChange={handleChangeOption}
            style={{ width: 200 }}
            options={filterOptions}
          />
          <Input 
            size="large"
            style={{ width: 300 }}
            onChange={handleChangeValue}
            value={filterSearchValue} />
        </Space>
        <Space align='end'
          style={{ flexBasis: '100%', flexDirection: 'column-reverse' }}
          >
          <Button 
            size="large" 
            type="primary"
            icon={<PlusOutlined />}
            style={{ width: 200 }}
            iconPosition='start'
            onClick={initCustomer}
          >
            Add Customer
          </Button>
        </Space>
      </Flex>
      <Flex gap="middle" vertical style={{marginTop: '24px'}}>
        <Table
          columns={tableColumns}
          rowKey={(record) => record.id}
          dataSource={data}
          pagination={tableParams.pagination}
          loading={{
            spinning: loading,
            indicator: <Spinner className="spinner"/>
          }}
          onChange={handleTableChange}
        />
      </Flex>
      <Modal
        title={selectedData.isActive === false ? "Add New Customer" : "Edit Customer"}
        open={isOpenModal}
        width={700}
        closable={false}
        footer={[]}
      >
        <Form
          form={form}
          initialValues={selectedData}
          name="customer-form"
          className="customer-form"
          layout="vertical"
          autoComplete="off"
          autoFocus={true}
          onFinish={onFinish}
        >
          <Space>
            <Form.Item
              layout="vertical"
              label="ID Pelanggan"
              name="customerName"
              style={{width: '212px'}}
              rules={[
                {
                  required: true,
                  message: 'Please input ID pelanggan!',
                },
              ]}
            >
              <Input size="large"/>
            </Form.Item>
            <Form.Item
              layout="vertical"
              label="Nama Toko"
              name="storeName"
              style={{width: '432px'}}
              rules={[
                {
                  required: true,
                  message: 'Please input nama toko!',
                },
              ]}
            >
              <Input size="large"/>
            </Form.Item>
          </Space>
          <Space>
            <Form.Item
              layout="vertical"
              label="Provinsi"
              name="province"
              style={{width: '212px'}}
              rules={[
                {
                  required: true,
                  message: 'Please select the province',
                },
              ]}
            >
              <Select 
                showSearch
                placeholder="Select a provinsi"
                options={provinceList} 
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                optionLabelProp="label"
                onChange={(value) => {
                  console.log(value);
                  form.setFieldValue('city', null);
                  fetchCityDropdown("city");
                  fetchCityDropdown("capital");
                  fetchCityDropdown("capital-id");
                }}
                size="large" />
            </Form.Item>
            <Form.Item
              layout="vertical"
              label="Kabupaten / Kota"
              name="city"
              style={{width: '212px'}}
              rules={[
                {
                  required: true,
                  message: 'Please select kabupaten/kota',
                },
              ]}
            >
              <Select 
                showSearch
                placeholder="Select kabupaten / kota"
                options={cityList} 
                onChange={(value) => {
                  reassignProvince(value);
                }}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                optionLabelProp="label"
                size="large" />
            </Form.Item>
            <Form.Item
              layout="vertical"
              label="Ibukota"
              name="city"
              style={{width: '212px'}}
              rules={[
                {
                  required: true,
                  message: 'Please select ibukota',
                },
              ]}
            >
              <Select 
                showSearch
                placeholder="Select ibukota"
                options={capitalList} 
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                optionLabelProp="label"
                onChange={(value) => {
                  reassignProvince(value);
                }}
                size="large" />
            </Form.Item>
          </Space>
          
          <Space>
            <Form.Item
              layout="vertical"
              label="Singkatan Ibukota"
              name="city"
              style={{width: '212px'}}
            >
              <Select 
                showSearch
                disabled
                placeholder="Select singkatan"
                options={capitalIdList} 
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                optionLabelProp="label"
                size="large" />
            </Form.Item>
            <Form.Item
              layout="vertical"
              label="No. Handphone"
              name="phoneNumber"
              style={{width: '212px'}}
              rules={[
                {
                  pattern: /^(08|02)\d{0,11}$/,
                  message: 'Format invalid',
                },
              ]}
            >
              <Input size="large"/>
            </Form.Item>
            <Form.Item
              layout="vertical"
              label="No. Telephone"
              name="homePhoneNumber"
              style={{width: '212px'}}
              rules={[
                {
                  pattern: /^(08|02)\d{0,11}$/,
                  message: 'Format invalid',
                },
              ]}
            >
              <Input size="large"/>
            </Form.Item>
          </Space>
          
          <Form.Item
            layout="vertical"
            label="Alamat"
            name="address"
            rules={[
              {
                required: true,
                message: 'Please input your alamat!',
              },
            ]}
          >
            <Input.TextArea 
              autoSize={{ minRows: 3, maxRows: 5 }}/>
          </Form.Item>
          
          <Flex gap="middle" vertical style={{marginTop: '24px', marginBottom: '16px'}}>
            <Table
              columns={expeditionColumns}
              rowKey={(record) => record.id}
              dataSource={customerExpeditionData}
              pagination={tableParamsExpedition.pagination}
              loading={{
                spinning: loadingExpedition,
                indicator: <Spinner className="spinner"/>
              }}
              onChange={handleTableExpeditionChange}
            />
          </Flex>
          <Space>
            <Select 
              showSearch
              style={{width: '294px'}}
              value={selectedExpedition}
              placeholder="Select a expedition"
              onChange={setSelectedExpedition}
              options={expeditionList} 
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              optionLabelProp="label"
              size="large" />
            <Button type="primary" size="large" style={{marginLeft: '8px'}} onClick={addExpedition}> 
              Add Expedition
            </Button>
          </Space>
          <Space
            align='end'
            style={{ flexBasis: '100%', flexDirection: 'column-reverse', width: '100%', marginTop: '28px', }}
          >
            <Form.Item>
              <Button htmlType="button" size="large" style={{marginRight: '8px'}} onClick={onCancel}> 
                Cancel
              </Button>
              <Button type="primary" htmlType="submit" size="large">
                {selectedData.isActive === true ? "Edit" : "Add"}
              </Button>
            </Form.Item>
          </Space>
        </Form>
      </Modal>
    </Context.Provider>
  )
}

const Customer = () => {
  return (
    <MasterLayout
      pageName="Customer"
      activeMenu="master-customer" 
      activeParent="master"
      content={Content()}
    />
  )
}

export default Customer;