import './Dashboard.scss';
import MasterLayout from '../../components/MasterLayout';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import * as dashboardApi from '../../stores/business/DashboardApi';
import React, { useEffect, useState } from 'react';
import { 
  notification,
} from 'antd';
import { useDispatch } from 'react-redux';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Context = React.createContext({
  name: 'Default',
});

const Content = () => {
  const [api, contextHolder] = notification.useNotification();
  const dispatch = useDispatch();

  const openNotification = (message, description, type) => {
    if (type === "error") {
      api.error({
        duration: 3,
        message: message,
        description: description,
        placement: 'top',
      });
    } else if (type === "success") {
      api.success({
        duration: 3,
        message: message,
        description: description,
        placement: 'top',
      });
    }
  };

  const [loadingJenisPakaian, setLoadingJenisPakaian] = useState(false);
  const [dataJenisPakaian, setDataJenisPakaian] = useState({
    labels: [],
    datasets: [],
  });

  const [loadingPo, setLoadingPo] = useState(false);
  const [dataPo, setDataPo] = useState({
    labels: [],
    datasets: [],
  });

  const getOptions = (title) => {
    return {
      responsive: true,
      plugins: {
        legend: {
          position: 'bottom',
        },
        title: {
          display: true,
          text: title,
          align: 'start', 
          font: {
            size: 20, 
            weight: 'bold', 
          },
          padding: {
            bottom: 40,
          },
          color: '#000000'
        },
      },
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    };
  };

  const fetchJenisPakaianChart = async () => {
    setLoadingJenisPakaian(true);
    const response = await dispatch(dashboardApi.getJenisPakaianChart());
    if (response.status === 200) {
      let keys = [];
      let values = [];
      for (let index = 0; index < response.data.length; index++) {
        keys.push(response.data[index].key);
        values.push(response.data[index].value);
      }
      setDataJenisPakaian({
        ...dataJenisPakaian,
        labels: keys,
        datasets: [
          {
            label: 'Jenis Pakaian',
            data: values,
            backgroundColor: '#184762',
            borderColor: '#184762',
            borderWidth: 1,
          },
        ],
      });
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
    setLoadingJenisPakaian(false);
  };

  const fetchPurchaseOrderChart = async () => {
    setLoadingPo(true);
    const response = await dispatch(dashboardApi.getPurchaseOrderChart());
    if (response.status === 200) {
      let keys = [];
      let values = [];
      for (let index = 0; index < response.data.length; index++) {
        keys.push(response.data[index].key);
        values.push(response.data[index].value);
      }
      setDataPo({
        ...dataPo,
        labels: keys,
        datasets: [
          {
            label: 'Purchase Order (PO)',
            data: values,
            backgroundColor: '#a05d7b',
            borderColor: '#a05d7b',
            borderWidth: 1,
          },
        ],
      });
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
    setLoadingPo(false);
  };

  useEffect(() => {
    fetchJenisPakaianChart();
    fetchPurchaseOrderChart();
  }, []);

  return (
    <Context.Provider value={true}>
      {contextHolder}
      {
        !loadingPo && 
        <div style={{ width: '100%', overflow: 'hidden' }}>
          <Bar data={dataPo} options={getOptions('Status Purchase Order (PO)')} />
        </div>
      }
      {
        !loadingJenisPakaian && 
        <div style={{ width: '100%', overflow: 'hidden' }}>
          <Bar data={dataJenisPakaian} options={getOptions('Jenis Pakaian')} />
        </div>
      }
    </Context.Provider>
  )
}

const Dashboard = () => {
  return (
    <MasterLayout
      pageName="Homepage"
      activeMenu="dashboard" 
      content={Content()}
    />
  )
}

export default Dashboard;