import './StokReturn.scss';
import MasterLayout from '../../components/MasterLayout';
import Spinner from '../../components/Spinner';
import React, { useEffect, useState } from 'react';
import { 
  Flex, 
  Select, 
  Space, 
  Input, 
  Button,
  Table,
  Modal,
  Form,
  notification,
  InputNumber,
} from 'antd';
import {
  PlusOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
} from '@ant-design/icons';
import * as stokReturnApi from '../../stores/business/StokReturnApi';
import * as customerApi from '../../stores/business/CustomerApi';
import * as hargaModalApi from '../../stores/business/HargaModalApi';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const Context = React.createContext({
  name: 'Default',
});

const { confirm } = Modal;

const Content = () => {
  const nav = useNavigate();
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const [api, contextHolder] = notification.useNotification();

  const openNotification = (message, description, type) => {
    if (type === "error") {
      api.error({
        duration: 3,
        message: message,
        description: description,
        placement: 'top',
      });
    } else if (type === "success") {
      api.success({
        duration: 3,
        message: message,
        description: description,
        placement: 'top',
      });
    }
  };

  const [loading, setLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [data, setData] = useState();
  const [filterOptionSelected, setFilterOptionSelected] = useState('namaToko');
  const [filterSearchValue, setFilterSearchValue] = useState('');
  const [tokoOpt, setTokoOpt] = useState([]);
  const [merekOpt, setMerekOpt] = useState([]);
  const [jenisPakaianOpt, setJenisPakaianOpt] = useState([]);
  const [namaPakaianOpt, setNamaPakaianOpt] = useState([]);
  const [jenisBahanOpt, setJenisBahanOpt] = useState([]);
  const [sizeGroupOpt, setSizeGroupOpt] = useState([]);
  const [selectedData, setSelectedData] = useState({
    id: null,
    namaToko: null,
    namaMerek: null,
    jenisPakaian: null,
    namaPakaian: null,
    jenisBahan: null,
    sizeGroup: null,
    discountType: null,
    discountAmount: null,
    totalPieces: null,
    totalPrice: null,
    sizes: [
      {
        id: null,
        stokReturnId: null,
        ukuran: null,
        totalPieces: null,
        pricePerPiece: null,
      }
    ],
  });

  const discountTypeOpt = [
    {value: '%', label: 'Percent (%)'},
    {value: 'Rp', label: 'Rupiah (Rp)'},
  ];

  const filterOptions = [
    {value: 'namaToko', label: 'Nama Toko'},
    {value: 'namaMerek', label: 'Nama Merek'},
    {value: 'jenisPakaian', label: 'Jenis Pakaian'},
    {value: 'namaPakaian', label: 'Nama Pakaian'},
  ];
  
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
    sortField: "updatedAt",
    sortOrder: "descend",
  });

  const tableColumns = [
    {
      title: 'Nama Toko',
      dataIndex: 'namaToko',
      key: 'namaToko',
      sorter: true,
    },
    {
      title: 'Nama Merek',
      dataIndex: 'namaMerek',
      key: 'namaMerek',
      sorter: true,
    },
    {
      title: 'Jenis Pakaian',
      dataIndex: 'jenisPakaian',
      key: 'jenisPakaian',
      sorter: true,
    },
    {
      title: 'Nama Pakaian',
      dataIndex: 'namaPakaian',
      key: 'namaPakaian',
      sorter: true,
    },
    {
      title: 'Total Keseluruhan Piece',
      dataIndex: 'totalPieces',
      key: 'totalPieces',
      sorter: true,
      render: (_, record) => (
        record.totalPieces === null ? "-" : record.totalPieces
      ),
    },
    {
      title: 'Sub Total',
      dataIndex: 'totalPrice',
      key: 'totalPrice',
      sorter: true,
      render: (_, record) => (
        new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(record.totalPrice).trim().split(",")[0]
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button shape="circle" icon={<EditOutlined />} onClick={() => {onClickEdit(record)}}/>
          <Button shape="circle" icon={<DeleteOutlined />} onClick={() => {showDeleteConfirm(record);}}/>
        </Space>
      ),
    },
  ];

  const handleChangeOption = (value) => {
    setFilterOptionSelected(value);
  };

  const handleChangeValue = (e) => {
    setFilterSearchValue(e.target.value);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    var sortField = sorter.field;
    var sortOrder = sorter.order;
    setTableParams({
      pagination,
      filters,
      sortOrder: sortOrder,
      sortField: sortField,
    });
    
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  const onClickEdit = (record) => {
    setSelectedData(record);
    form.setFieldsValue({
      ...record,
      discountAmount: record.discountType === "Rp" ? new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(record.discountAmount).replace('Rp', '').trim().split(",")[0] : record.discountAmount,
      totalPrice: new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(record.totalPrice).replace('Rp', '').trim().split(",")[0],
    });

    setIsOpenModal(true);
  };

  const showDeleteConfirm = (record) => {
    confirm({
      title: 'Are you sure delete this stok?',
      icon: <ExclamationCircleFilled />,
      content: 'You can\'t undo this operation when finished!',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      centered: true,
      onOk() {
        deleteData(record);
      },
    });
  };

  const deleteData = async (record) => {
    setLoading(true);
    const response = await dispatch(stokReturnApi.deleteStok(record.id));
    if (response.status === 200) {
      resetData();
      if (tableParams.pagination.current > 1) {
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            current: 1,
          }
        });
      } else {
        fetchData();
      }
      openNotification('Success', 'Success to delete stok!', 'success');
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    }
    setLoading(false);
  };

  const fetchData = async () => {
    setLoading(true);

    var sortField = tableParams.sortField === undefined ? "updatedAt" : tableParams.sortField;
    var sortOrder = tableParams.sortOrder === "ascend" ? "asc" : "desc";

    if (tableParams.sortOrder === undefined || tableParams.sortOrder === null) {
      sortOrder = "desc";
      sortField = "updatedAt";
    }

    const response = await dispatch(stokReturnApi.getPage({
      "page": tableParams.pagination.current,
      "size": tableParams.pagination.pageSize,
      "sort": [
        sortField + " " + sortOrder,
      ],
      "search": [
        {
          field: filterOptionSelected,
          value: filterSearchValue
        }
      ]
    }));
    if (response.status === 200) {
      setData(response.data.contents);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: response.data.totalData,
        }
      });
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
    setLoading(false);
  };

  const fetchToko = async () => {
    const response = await dispatch(customerApi.getTokoList());
    if (response.status === 200) {
      setTokoOpt(response.data);
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
  };

  const fetchDropdown = async (type) => {
    const response = await dispatch(hargaModalApi.getDropdown(type, {
      jenisBahan: selectedData.jenisBahan === null ? '' : selectedData.jenisBahan,
      jenisPakaian: selectedData.jenisPakaian === null ? '' : selectedData.jenisPakaian,
      namaMerek: selectedData.namaMerek === null ? '' : selectedData.namaMerek,
      sizeGroup: '',
      namaPakaian: selectedData.namaPakaian === null ? '' : selectedData.namaPakaian,
    }));
    if (response.status === 200) {
      if (type === 'jenis-bahan') {
        setJenisBahanOpt(response.data);
      } else if (type === 'jenis-pakaian') {
        setJenisPakaianOpt(response.data);
      } else if (type === 'nama-merek') {
        setMerekOpt(response.data);
      } else if (type === 'nama-pakaian') {
        setNamaPakaianOpt(response.data);
      } else if (type === 'size-group') {
        setSizeGroupOpt(response.data);
      }
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
  };

  const resetData = () => {
    form.resetFields();
    form.setFieldsValue({
      id: null,
      namaToko: null,
      namaMerek: null,
      jenisPakaian: null,
      namaPakaian: null,
      jenisBahan: null,
      sizeGroup: null,
      discountType: null,
      discountAmount: null,
      totalPieces: null,
      totalPrice: null,
      sizes: [
        {
          id: null,
          stokReturnId: null,
          ukuran: null,
          totalPieces: null,
          pricePerPiece: null,
        }
      ],
    });
    setSelectedData({
      id: null,
      namaToko: null,
      namaMerek: null,
      jenisPakaian: null,
      namaPakaian: null,
      jenisBahan: null,
      sizeGroup: null,
      discountType: null,
      discountAmount: null,
      totalPieces: null,
      totalPrice: null,
      sizes: [
        {
          id: null,
          stokReturnId: null,
          ukuran: null,
          totalPieces: null,
          pricePerPiece: null,
        }
      ],
    });
  }

  const onCancel = () => {
    resetData();
    fetchData();
    setIsOpenModal(false);
  };
  
  const submit = async (values) => {
    if (selectedData.id === null) {
      const response = await dispatch(stokReturnApi.init(values));

      if (response.status === 200) {
        setSelectedData(response.data);
        form.setFieldsValue(response.data);
        if (tableParams.pagination.current > 1) {
          setTableParams({
            ...tableParams,
            pagination: {
              ...tableParams.pagination,
              current: 1,
            }
          });
        } else {
          fetchData();
        }
      } else if (response.status === 403) {
        openNotification('Forbidden', response.errors[0].message, 'error');
      } else if (response.status === 400) {
        openNotification('Error', response.errors[0].message, 'error');
      } else {
        openNotification('Error', 'Something went wrong. Please try again', 'error');
      }
    } else {
      setIsOpenModal(false);
      const response = await dispatch(stokReturnApi.post(selectedData));
      if (response.status === 200) {
        if (tableParams.pagination.current > 1) {
          setTableParams({
            ...tableParams,
            pagination: {
              ...tableParams.pagination,
              current: 1,
            }
          });
        } else {
          fetchData();
        }
        openNotification('Success', 'Success to update data!', 'success');
        resetData();
      } else if (response.status === 403) {
        openNotification('Forbidden', response.errors[0].message, 'error');
      } else if (response.status === 400) {
        openNotification('Error', response.errors[0].message, 'error');
        setIsOpenModal(true);
      } else {
        openNotification('Error', 'Something went wrong. Please try again', 'error');
        setIsOpenModal(true);
      }
    }
  };

  const reassignSize = (id, value) => {
    let selectedDataNew = selectedData;
    selectedDataNew.sizes.map(obj => {
      if (obj.id === id) {
        obj.totalPieces = value;
      }
    });

    setSelectedData(selectedDataNew);
    recalculatePrice();
  }

  const recalculatePrice = () => {
    let totalPrice = 0;
    let totalPieces = 0;
    selectedData.sizes.map(obj => {
      if (obj.totalPieces !== null && obj.totalPieces !== undefined) {
        totalPrice += obj.totalPieces * obj.pricePerPiece;
        totalPieces += obj.totalPieces;
      }
    });

    setSelectedData({
      ...selectedData,
      totalPrice: totalPrice,
      totalPieces: totalPieces,
    });
    if (selectedData.discountType !== null && selectedData.discountType !== undefined && selectedData.discountAmount !== null && selectedData.discountAmount !== undefined) {
      if (selectedData.discountType === "%") {
        totalPrice -= Math.floor(selectedData.discountAmount * totalPrice / 100);
      } else if (selectedData.discountType === "Rp") {
        totalPrice -= selectedData.discountAmount;
      }
    }

    form.setFieldValue("totalPriceItem", new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(totalPrice).replace('Rp', '').trim().split(",")[0])
  }

  useEffect(() => {
    fetchData();
  }, [
    filterOptionSelected,
    filterSearchValue,
    tableParams.pagination?.current,
    tableParams.pagination?.pageSize,
    tableParams?.sortOrder,
    tableParams?.sortField,
  ]);

  useEffect(() => {
    fetchDropdown('jenis-bahan');
    fetchDropdown('jenis-pakaian');
    fetchDropdown('nama-pakaian');
    fetchDropdown('nama-merek');
    fetchDropdown('size-group');
  }, [
    selectedData.jenisBahan,
    selectedData.jenisPakaian,
    selectedData.namaMerek,
    selectedData.sizeGroup,
    selectedData.namaPakaian,
  ]);

  useEffect(() => {
    recalculatePrice();
  }, [
    selectedData.discountAmount, 
    selectedData.discountType
  ]);

  useEffect(() => {
    fetchToko();
  }, []);

  return (
    <Context.Provider value={true}>
      {contextHolder}
      
      <Flex gap="middle" vertical={false}>
        <Space>
          <Select
            size="large"
            value={filterOptionSelected}
            onChange={handleChangeOption}
            style={{ width: 250 }}
            options={filterOptions}
          />
          <Input 
            size="large"
            style={{ width: 300 }}
            onChange={handleChangeValue}
            value={filterSearchValue} />
        </Space>
        <Space align='end' style={{ justifyContent: 'end', width: '100%' }}>
          <Button 
            size="large" 
            type="primary"
            icon={<PlusOutlined />}
            iconPosition='start'
            onClick={() => {
              setIsOpenModal(true);
            }}
          >
            Add Stok
          </Button>
        </Space>
      </Flex>

      <Flex gap="middle" vertical style={{marginTop: '24px'}}>
        <Table
          columns={tableColumns}
          rowKey={(record) => record.id}
          dataSource={data}
          pagination={tableParams.pagination}
          loading={{
            spinning: loading,
            indicator: <Spinner className="spinner"/>
          }}
          onChange={handleTableChange}
        />
      </Flex>

      <Modal
        title={selectedData.id === null ? "Add Stok" : "Edit Stok"}
        open={isOpenModal}
        width={900}
        closable={false}
        footer={[]}
      >
        <Form
          form={form}
          name="form"
          className="form"
          layout="vertical"
          autoComplete="off"
          autoFocus={true}
          onFinish={submit}
        >
          <Space>
            <Form.Item
              layout="vertical"
              label="Nama Toko"
              name="namaToko"
              style={{width: '422px'}}
              rules={[
                {
                  required: true,
                  message: 'Pilih nama toko',
                },
              ]}
            >
              <Select 
                disabled={selectedData.id !== null}
                showSearch
                placeholder="Pilih nama toko"
                options={tokoOpt} 
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                optionLabelProp="label"
                size="large" />
            </Form.Item>
            <Form.Item
              layout="vertical"
              label="Nama Merek"
              name="namaMerek"
              style={{width: '422px'}}
              rules={[
                {
                  required: true,
                  message: 'Pilih nama merek',
                },
              ]}
            >
              <Select 
                disabled={selectedData.id !== null}
                showSearch
                allowClear
                placeholder="Pilih nama merek"
                options={merekOpt} 
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                optionLabelProp="label"
                onChange={(data) => {
                  setSelectedData({
                    ...selectedData,
                    namaMerek: data
                  });
                }}
                size="large" />
            </Form.Item>
          </Space>

          <Space>
            <Form.Item
              layout="vertical"
              label="Jenis Pakaian"
              name="jenisPakaian"
              style={{width: '422px'}}
              rules={[
                {
                  required: true,
                  message: 'Pilih jenis pakaian',
                },
              ]}
            >
              <Select 
                disabled={selectedData.id !== null}
                showSearch
                allowClear
                placeholder="Pilih jenis pakaian"
                options={jenisPakaianOpt} 
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                optionLabelProp="label"
                onChange={(data) => {
                  setSelectedData({
                    ...selectedData,
                    jenisPakaian: data
                  });
                }}
                size="large" />
            </Form.Item>
            <Form.Item
              layout="vertical"
              label="Nama Pakaian"
              name="namaPakaian"
              style={{width: '422px'}}
              rules={[
                {
                  required: true,
                  message: 'Pilih nama pakaian',
                },
              ]}
            >
              <Select 
                disabled={selectedData.id !== null}
                showSearch
                allowClear
                placeholder="Pilih nama pakaian"
                options={namaPakaianOpt} 
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                onChange={(data) => {
                  setSelectedData({
                    ...selectedData,
                    namaPakaian: data
                  });
                }}
                optionLabelProp="label"
                size="large" />
            </Form.Item>
          </Space>

          <Space>
            <Form.Item
              layout="vertical"
              label="Jenis Bahan"
              name="jenisBahan"
              style={{width: '422px'}}
              rules={[
                {
                  required: true,
                  message: 'Pilih jenis bahan',
                },
              ]}
            >
              <Select 
                disabled={selectedData.id !== null}
                showSearch
                allowClear
                placeholder="Pilih jenis bahan"
                options={jenisBahanOpt} 
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                optionLabelProp="label"
                onChange={(data) => {
                  setSelectedData({
                    ...selectedData,
                    jenisBahan: data
                  });
                }}
                size="large" />
            </Form.Item>
            <Form.Item
              layout="vertical"
              label="Ukuran"
              name="sizeGroup"
              style={{width: '422px'}}
              rules={[
                {
                  required: true,
                  message: 'Pilih ukuran',
                },
              ]}
            >
              <Select 
                disabled={selectedData.id !== null}
                showSearch
                allowClear
                placeholder="Pilih ukuran"
                options={sizeGroupOpt} 
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                optionLabelProp="label"
                onChange={(data) => {
                  setSelectedData({
                    ...selectedData,
                    sizeGroup: data
                  });
                }}
                size="large" />
            </Form.Item>
          </Space>
          {
            selectedData.id !== null && 
            <Space style={{marginBottom: '12px'}}>
              <div
                style={{width: '278px'}}
              >
                Size
              </div>
              <div
                style={{width: '278px'}}
              >
                Jumlah Piece
              </div>
              <div
                style={{width: '278px'}}
              >
                Harga Jual Toko per Piece
              </div>
            </Space>
          }
          {
            selectedData.id !== null &&
            selectedData.sizes?.map(obj => (
              <Space key={obj.id}>
                <Form.Item
                  layout="vertical"
                  label=""
                  name={"size" +obj.id}
                  initialValue={obj.ukuran}
                  style={{width: '278px'}}
                >
                  <Input size="large" placeholder='Ukuran' disabled />
                </Form.Item>
                <Form.Item
                  layout="vertical"
                  label=""
                  name={"jumlah"+obj.id}
                  initialValue={obj.totalPieces}
                  style={{width: '278px'}}
                >
                  <InputNumber 
                    style={{width: '100%'}} 
                    size="large" 
                    placeholder='Jumlah pieces' 
                    onChange={(value) => {
                      reassignSize(obj.id, value);
                    }}
                  />
                </Form.Item>
                <Form.Item
                  layout="vertical"
                  label=""
                  name={"hargaJualPerPiece" + obj.id}
                  style={{width: '278px'}}
                  initialValue={new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(obj.pricePerPiece).replace('Rp', '').trim().split(",")[0]}
                >
                  <Input size='large' addonBefore="Rp" min={0} style={{width: '100%'}} disabled
                    placeholder="Harga Jual Toko per Piece" />
                </Form.Item>
              </Space>
            ))
          }

          {
            selectedData.id !== null && 
            <Space>
              <Form.Item
                layout="vertical"
                label="Jenis Diskon"
                name="discountType"
                style={{width: '278px'}}
              >
                <Select 
                  showSearch
                  allowClear
                  placeholder="Pilih jenis diskon"
                  options={discountTypeOpt} 
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  optionLabelProp="label"
                  onChange={(data) => {
                    setSelectedData({
                      ...selectedData,
                      discountType: data === undefined ? null : data,
                      discountAmount: null,
                    });
                    form.setFieldValue("discountAmount", null);
                  }}
                  size="large" />
              </Form.Item>
              <Form.Item
                layout="vertical"
                label="&nbsp;"
                name="discountAmount"
                style={{width: '278px'}}
                rules={[
                  {
                    required: selectedData.discountType !== null && selectedData.discountType !== "" && selectedData.discountType !== undefined,
                    message: 'Please input nominal diskon',
                  },
                ]}
              >
                {
                  selectedData.discountType !== "Rp" &&
                  <InputNumber size='large' min={0} style={{width: '100%'}} 
                    onBlur={(e) => {
                      setSelectedData({
                        ...selectedData,
                        discountAmount: e.target.value
                      });
                    }}
                    placeholder="Jumlah diskon"/>
                }
                {
                  selectedData.discountType === "Rp" &&
                  <Input size='large' style={{width: '100%'}} addonBefore="Rp"
                    onBlur={(e) => {
                      let value = e.target.value.replace(/\D/g, '');
                      let formattedNumber = new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(value).replace('Rp', '').trim().split(",")[0];
                      form.setFieldValue('discountAmount', formattedNumber);

                      setSelectedData({
                        ...selectedData,
                        discountAmount: parseInt(formattedNumber.replaceAll('.', ''))
                      });
                    }}
                    placeholder="Jumlah diskon"/>
                }
              </Form.Item>
              <Form.Item
                layout="vertical"
                label="Sub Total"
                name="totalPriceItem"
                style={{width: '278px'}}
                initialValue={new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(selectedData.totalPrice).replace('Rp', '').trim().split(",")[0]}
              >
                <Input size='large' addonBefore="Rp" min={0} style={{width: '100%'}} disabled
                  placeholder="Sub total" />
              </Form.Item>
            </Space>
          }

          <Space
            align='end'
            style={{ flexBasis: '100%', flexDirection: 'column-reverse', width: '100%', marginTop: '28px', }}
          >
            <Form.Item>
              <Button htmlType="button" size="large" style={{marginRight: '8px'}} onClick={onCancel}> 
                Cancel
              </Button>
              <Button type="primary" htmlType="submit" size="large">
                {selectedData.id === null ? "Save to Continue" : "Edit"}
              </Button>
            </Form.Item>
          </Space>
        </Form>
      </Modal>
    </Context.Provider>
  )
}

const StokReturn = () => {
  return (
    <MasterLayout
      pageName="Stok Retur"
      activeMenu="stok-return" 
      activeParent="stok-return"
      content={Content()}
    />
  )
}

export default StokReturn;