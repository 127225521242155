import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
  LogoutOutlined,
  DatabaseOutlined,
  UserSwitchOutlined,
  UsergroupAddOutlined,
  ExperimentOutlined,
  SkinOutlined,
  PictureOutlined,
  TagOutlined,
  PicCenterOutlined,
  CustomerServiceOutlined,
  LinkOutlined,
  AreaChartOutlined,
  SnippetsOutlined,
  ScissorOutlined,
  FormOutlined,
  DollarOutlined,
  BankOutlined,
  DiffOutlined,
  RollbackOutlined,
  ContainerOutlined,
} from '@ant-design/icons';
import { 
  Button, 
  Layout, 
  Menu, 
  theme, 
  Divider, 
} from 'antd';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { GetFullName, RemoveToken } from '../libs/Auth';
import * as authApi from '../stores/business/AuthApi';
import './MasterLayout.scss';
import Logo from './Logo';

const { Header, Sider, Content, Footer } = Layout;

const getItem = (label, key, icon, children) => {
  return {
    key,
    icon,
    children,
    label,
  };
}

const MasterLayout = ({
  pageName,
  activeMenu,
  activeParent,
  content
}) => {
  const [collapsed, setCollapsed] = useState(false);
  const [items, setItems] = useState([]);
  const [roles, setRoles] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const logout = () => {
    RemoveToken();
    navigate('/login');
  }

  const fetchMenu = async () => {
    const response = await dispatch(authApi.getMenu());
    if (response.status === 200) {
      constructMenu(response.data);
    } 
  }

  const fetchRoles = async () => {
    const response = await dispatch(authApi.getRoles());
    if (response.status === 200) {
      setRoles(response.data);
    } 
  }

  const setRedirectTo = (activeMenu) => {
    let redirectTo = "";
    switch (activeMenu) {
      case 'master-user':
        redirectTo = '/master/user';
        break;
      case 'master-role':
        redirectTo = '/master/role';
        break;
      case 'master-customer':
        redirectTo = '/master/customer';
        break;
      case 'master-bahan':
        redirectTo = '/master/bahan';
        break;
      case 'master-merek':
        redirectTo = '/master/merek';
        break;
      case 'master-pakaian':
        redirectTo = '/master/pakaian';
        break;
      case 'master-gambar':
        redirectTo = '/master/gambar';
        break;
      case 'master-aksesoris':
        redirectTo = '/master/aksesoris';
        break;
      case 'master-variasi':
        redirectTo = '/master/variasi';
        break;
      case 'harga-modal':
        redirectTo = '/master/harga-modal';
        break;
      case 'stok-bahan':
        redirectTo = '/stok/bahan';
        break;
      case 'po-produksi':
        redirectTo = '/po/produksi';
        break;
      case 'po-administrasi':
        redirectTo = '/po/administrasi';
        break;
      default:
        break;
    }
    
    window.localStorage.setItem('reason-season-redirect-to', redirectTo);
  }

  const constructMenu = (list) => {
    let menu = [];
    menu.push(getItem((<a href="/" target="_self">Homepage</a>), 'dashboard', <AreaChartOutlined />));

    let masterDataList = [];
    if (list.includes("MASTER_USER")) masterDataList.push(getItem((<a href="/master/user" target="_self">User</a>), 'master-user', <UserOutlined />));
    if (list.includes("MASTER_ROLE")) masterDataList.push(getItem((<a href="/master/role" target="_self">Role</a>), 'master-role', <UserSwitchOutlined />));
    if (list.includes("MASTER_CUSTOMER")) masterDataList.push(getItem((<a href="/master/customer" target="_self">Customer</a>), 'master-customer', <UsergroupAddOutlined />)); 
    if (list.includes("MASTER_BAHAN")) masterDataList.push(getItem((<a href="/master/bahan" target="_self">Bahan</a>), 'master-bahan', <ExperimentOutlined />)); 
    if (list.includes("MASTER_MEREK")) masterDataList.push(getItem((<a href="/master/merek" target="_self">Merek</a>), 'master-merek', <TagOutlined />));
    if (list.includes("MASTER_PAKAIAN")) masterDataList.push(getItem((<a href="/master/pakaian" target="_self">Pakaian</a>), 'master-pakaian', <SkinOutlined />)); 
    if (list.includes("MASTER_GAMBAR")) masterDataList.push(getItem((<a href="/master/gambar" target="_self">Gambar</a>), 'master-gambar', <PictureOutlined />));
    if (list.includes("MASTER_AKSESORIS")) masterDataList.push(getItem((<a href="/master/aksesoris" target="_self">Aksesoris</a>), 'master-aksesoris', <CustomerServiceOutlined />));
    if (list.includes("MASTER_VARIASI")) masterDataList.push(getItem((<a href="/master/variasi" target="_self">Variasi</a>), 'master-variasi', <LinkOutlined />));
    if (list.includes("HARGA_MODAL")) masterDataList.push(getItem((<a href="/master/harga-modal" target="_self">Harga Modal</a>), 'harga-modal', <DollarOutlined />));
    if (list.includes("HARGA_JUAL")) masterDataList.push(getItem((<a href="/master/harga-jual" target="_self">Harga Jual</a>), 'harga-jual', <DollarOutlined />));

    if (masterDataList.length > 0) {
      menu.push(getItem('Master Data', 'master', <DatabaseOutlined />, masterDataList));
    }

    if (list.includes("STOK_BAHAN")) menu.push(getItem((<a href="/stok/bahan" target="_self">Stok Bahan</a>), 'stok-bahan', <PicCenterOutlined />));
    if (list.includes("STOK_GUDANG")) menu.push(getItem((<a href="/stok/gudang" target="_self">Stok Gudang</a>), 'stok-gudang', <BankOutlined />));
    if (list.includes("STOK_TOKO")) menu.push(getItem((<a href="/stok/toko" target="_self">Stok Toko</a>), 'stok-toko', <BankOutlined />));
    if (list.includes("STOK_RETURN")) menu.push(getItem((<a href="/stok/return" target="_self">Stok Retur</a>), 'stok-return', <RollbackOutlined />));

    let poList = [];

    if (list.includes("PO") && roles.includes("ADMIN_PRODUCTION")) poList.push(getItem((<a href="/po/produksi" target="_self">Produksi</a>), 'po-produksi', <ScissorOutlined />));
    if (list.includes("PO") && roles.includes("ADMIN_FINANCE")) poList.push(getItem((<a href="/po/administrasi" target="_self">Administrasi</a>), 'po-administrasi', <FormOutlined />));
    
    if (poList.length > 0) {
      menu.push(getItem('Purchase Order (PO)', 'po', <SnippetsOutlined />, poList));
    }

    let laporanPiutangList = [];
    if (list.includes("LAPORAN_PIUTANG_GUDANG_TOKO")) laporanPiutangList.push(getItem((<a href="/laporan-piutang/gudang-toko" target="_self">Gudang ke Toko</a>), 'laporan-piutang-gudang-toko', <DiffOutlined />));
    if (list.includes("LAPORAN_PIUTANG_GUDANG_CUSTOMER")) laporanPiutangList.push(getItem((<a href="/laporan-piutang/gudang-customer" target="_self">Gudang ke Customer</a>), 'laporan-piutang-gudang-customer', <DiffOutlined />));
    if (list.includes("LAPORAN_PIUTANG_TOKO_CUSTOMER")) laporanPiutangList.push(getItem((<a href="/laporan-piutang/toko-customer" target="_self">Toko ke Customer</a>), 'laporan-piutang-toko-customer', <DiffOutlined />));
    if (list.includes("AKUMULASI_PIUTANG")) laporanPiutangList.push(getItem((<a href="/laporan-piutang/akumulasi" target="_self">Akumulasi Piutang</a>), 'laporan-akumulasi-piutang', <DiffOutlined />));

    if (laporanPiutangList.length > 0) {
      menu.push(getItem('Laporan Piutang', 'laporan-piutang', <DiffOutlined />, laporanPiutangList));
    }

    if (list.includes("SALES_INVOICE")) menu.push(getItem((<a href="/sales-invoice" target="_self">Sales Invoice</a>), 'sales-invoice', <ContainerOutlined />));
    
    setItems(menu);
  }

  useEffect(() => {
    setRedirectTo(activeMenu);
    fetchRoles();
  }, []);

  useEffect(() => {
    fetchMenu();
  }, [roles]);

  return (
    <Layout>
      <Sider 
        trigger={null} 
        collapsible 
        collapsed={collapsed} 
        className="sider"
      >
        <div className="flex">
          <Logo className="logo"></Logo>
        </div>
        <Menu
          theme="dark"
          mode="inline"
          className="sider-menu"
          defaultSelectedKeys={[activeMenu]}
          defaultOpenKeys={[activeParent]}
          items={items}
        />
      </Sider>
      <Layout>
        <Header
          style={{
            padding: 0,
            background: colorBgContainer,
          }}
        >
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: '16px',
              width: 64,
              height: 64,
            }}
          />
          <div className="logout-btn">
            <label>{GetFullName()} &nbsp;</label>
            <Button shape="circle" icon={<LogoutOutlined />} onClick={logout}/>
          </div>
        </Header>
        <Content
          style={{
            margin: '24px 16px',
            padding: 24,
            minHeight: 280,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          <h1>{pageName}</h1>
          <Divider />
          { content }
        </Content>
        <Footer
          style={{
            textAlign: 'center',
          }}
        >
          IPR
        </Footer>
      </Layout>
    </Layout>
  )
}

MasterLayout.propTypes = {
  pageName: PropTypes.string,
  activeMenu: PropTypes.string,
  activeParent: PropTypes.string,
  content: PropTypes.element
};

export default MasterLayout;