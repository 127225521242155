import './Bahan.scss';
import MasterLayout from '../../components/MasterLayout';
import Spinner from '../../components/Spinner';
import React, { useEffect, useState } from 'react';
import * as masterDataApi from '../../stores/business/MasterDataApi';
import * as bahanApi from '../../stores/business/BahanApi';
import { 
  Flex, 
  Select, 
  Space, 
  Input, 
  InputNumber,
  Button,
  Table,
  Modal,
  Form,
  Segmented,
  notification,
} from 'antd';
import {
  PlusOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';


const Context = React.createContext({
  name: 'Default',
});

const { confirm } = Modal;

const Content = () => {
  const [api, contextHolder] = notification.useNotification();

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();

  const [warnaBahanOption, setWarnaBahanOption] = useState();
  const [supplierOption, setSupplierOption] = useState();
  const [jenisSettingOption, setJenisSettingOption] = useState();
  const [jenisBahanOption, setJenisBahanOption] = useState();
  const [selectedType, setSelectedType] = useState('HARGA PABRIK');
  const [filterOptionSelected, setFilterOptionSelected] = useState('jenis');
  const [filterSearchValue, setFilterSearchValue] = useState('');
  const [selectedData, setSelectedData] = useState({
    id: null,
    type: "",
    jenis: "",
    ketebalan: null,
    gramasi: null,
    warna: "",
    setting: null,
    jenisSetting: "",
    harga: null,
    supplier: ""
  });
  
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
    sortField: "id",
    sortOrder: "descend",
  });

  const tableColumnsHargaPabrik = [
    {
      title: 'Jenis Bahan',
      dataIndex: 'jenis',
      key: 'jenis',
      sorter: true,
    },
    {
      title: 'Ketebalan Bahan',
      dataIndex: 'ketebalan',
      key: 'ketebalan',
      sorter: true,
      render: (_, record) => (
        record.ketebalan + "s"
      ),
    },
    {
      title: 'Warna Bahan',
      dataIndex: 'warna',
      key: 'warna',
      sorter: true,
    },
    {
      title: 'Setting Bahan',
      dataIndex: 'setting',
      key: 'setting',
      sorter: true,
    },
    {
      title: 'Jenis Setting',
      dataIndex: 'jenisSetting',
      key: 'jenisSetting',
      sorter: true,
    },
    {
      title: 'Harga',
      dataIndex: 'harga',
      key: 'harga',
      sorter: true,
      render: (_, record) => (
        new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(record.harga).trim().split(",")[0]
      ),
    },
    {
      title: 'Nama Supplier',
      dataIndex: 'supplier',
      key: 'supplier',
      sorter: true,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button shape="circle" icon={<EditOutlined />} onClick={() => {onClickEdit(record)}}/>
          <Button shape="circle" icon={<DeleteOutlined />} onClick={() => {showDeleteConfirm(record);}}/>
        </Space>
      ),
    },
  ];

  const tableColumnsHargaModal = [
    {
      title: 'Jenis Bahan',
      dataIndex: 'jenis',
      key: 'jenis',
      sorter: true,
    },
    {
      title: 'Harga',
      dataIndex: 'harga',
      key: 'harga',
      sorter: true,
      render: (_, record) => (
        new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(record.harga).trim().split(",")[0]
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button shape="circle" icon={<EditOutlined />} onClick={() => {onClickEdit(record)}}/>
          <Button shape="circle" icon={<DeleteOutlined />} onClick={() => {showDeleteConfirm(record);}}/>
        </Space>
      ),
    },
  ];

  const filterOptions = [
    {value: 'jenis', label: 'Jenis Bahan'},
    {value: 'ketebalan', label: 'Ketebalan Bahan (angka)'},
    {value: 'warna', label: 'Warna Bahan'},
    {value: 'setting', label: 'Setting Bahan (angka)'},
    {value: 'jenisSetting', label: 'Jenis Setting Bahan (Yard / Inch)'},
    {value: 'harga', label: 'Harga (angka)'},
    {value: 'supplier', label: 'Nama Supplier'},
  ];

  const filterOptionsHargaModal = [
    {value: 'jenis', label: 'Jenis Bahan'},
    {value: 'harga', label: 'Harga (angka)'},
  ];

  const jenisHargaOption = [
    {value: 'HARGA PABRIK', label: 'Harga Pabrik'},
    {value: 'HARGA MODAL', label: 'Harga Modal'},
  ];

  const [form] = Form.useForm();
  const nav = useNavigate();
  const dispatch = useDispatch();

  const openNotification = (message, description, type) => {
    if (type === "error") {
      api.error({
        duration: 3,
        message: message,
        description: description,
        placement: 'top',
      });
    } else if (type === "success") {
      api.success({
        duration: 3,
        message: message,
        description: description,
        placement: 'top',
      });
    }
  };

  const fetchWarnaBahan = async () => {
    const response = await dispatch(masterDataApi.getWarnaBahan());
    if (response.status === 200) {
      setWarnaBahanOption(response.data);
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=master/bahan")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    }
  }

  const fetchSupplier = async () => {
    const response = await dispatch(masterDataApi.getSupplier());
    if (response.status === 200) {
      setSupplierOption(response.data);
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=master/bahan")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    }
  }

  const fetchJenisSetting = async () => {
    const response = await dispatch(masterDataApi.getJenisSetting());
    if (response.status === 200) {
      setJenisSettingOption(response.data);
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=master/bahan")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    }
  }

  const fetchJenisBahan = async () => {
    const response = await dispatch(masterDataApi.getJenisBahan());
    if (response.status === 200) {
      setJenisBahanOption(response.data);
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=master/bahan")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    }
  }

  const fetchData = async () => {
    setLoading(true);

    var sortField = tableParams.sortField === undefined ? "id" : tableParams.sortField;
    var sortOrder = tableParams.sortOrder === "ascend" ? "asc" : "desc"

    if (tableParams.sortOrder === undefined || tableParams.sortOrder === null) {
      sortOrder = "desc";
      sortField = "id";
    }

    const response = await dispatch(bahanApi.getPage({
      "page": tableParams.pagination.current,
      "size": tableParams.pagination.pageSize,
      "sort": [
        sortField + " " + sortOrder,
      ],
      "search": [
        {
          field: "type",
          value: selectedType
        },
        {
          field: filterOptionSelected,
          value: filterSearchValue
        }
      ]
    }));
    if (response.status === 200) {
      setData(response.data.contents);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: response.data.totalData,
        }
      });
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=master/bahan")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    }
    setLoading(false);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    var sortField = sorter.field;
    var sortOrder = sorter.order;
    setTableParams({
      pagination,
      filters,
      sortOrder: sortOrder,
      sortField: sortField,
    });
    
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  const handleChangeOption = (value) => {
    setFilterOptionSelected(value);
  };

  const handleChangeValue = (e) => {
    setFilterSearchValue(e.target.value);
  };

  const onClickEdit = (record) => {
    record = {
      ...record,
      harga: new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(record.harga).replace('Rp', '').trim().split(",")[0]
    }
    setSelectedData(record);
    form.setFieldsValue(record);
    setIsOpenModal(true);
  }

  const showDeleteConfirm = (record) => {
    confirm({
      title: 'Are you sure delete this bahan?',
      icon: <ExclamationCircleFilled />,
      content: 'You can\'t undo this operation when finished!',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      centered: true,
      onOk() {
        deleteData(record);
      },
    });
  };

  const deleteData = async (record) => {
    setLoading(true);
    const response = await dispatch(bahanApi.deleteBahan(record.id));
    if (response.status === 200) {
      form.setFieldsValue({
        id: null,
        type: selectedType,
        jenis: "",
        ketebalan: null,
        gramasi: null,
        warna: "",
        setting: null,
        jenisSetting: "",
        harga: null,
        supplier: ""
      });
      setSelectedData({
        id: null,
        type: selectedType,
        jenis: "",
        ketebalan: null,
        gramasi: null,
        warna: "",
        setting: null,
        jenisSetting: "",
        harga: null,
        supplier: ""
      });
      if (tableParams.pagination.current > 1) {
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            current: 1,
          }
        });
      } else {
        fetchData();
      }
      openNotification('Success', 'Success to delete bahan!', 'success');
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=master/bahan")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    }
    setLoading(false);
  };

  const addBahan = () => {
    form.setFieldsValue({
      id: null,
      type: selectedType,
      jenis: "",
      ketebalan: null,
      gramasi: null,
      warna: "",
      setting: null,
      jenisSetting: "",
      harga: null,
      supplier: ""
    });
    setSelectedData({
      id: null,
      type: selectedType,
      jenis: "",
      ketebalan: null,
      gramasi: null,
      warna: "",
      setting: null,
      jenisSetting: "",
      harga: null,
      supplier: ""
    });
    setIsOpenModal(true);
  }

  const onFinish = async (values) => {
    setIsOpenModal(false);
    if (selectedType === "HARGA MODAL") {
      values = {
        id: values.id,
        harga: values.harga.replace(/\D/g, ''),
        type: values.type,
        jenis: values.jenis,
      }
    } else {
      values = {
        ...values,
        harga: values.harga.replace(/\D/g, ''),
      }
    }
    const response = await (selectedData.id === null ? dispatch(bahanApi.create(values)) : dispatch(bahanApi.update({
      ...values,
      id: selectedData.id,
    })));
    if (response.status === 200) {
      let message = selectedData.id !== null ? 'Success to edit bahan!' : 'Success to add new bahan!';
      openNotification('Success', message, 'success');
      form.setFieldsValue({
        id: null,
        type: selectedType,
        jenis: "",
        ketebalan: null,
        gramasi: null,
        warna: "",
        setting: null,
        jenisSetting: "",
        harga: null,
        supplier: ""
      });
      setSelectedData({
        id: null,
        type: selectedType,
        jenis: "",
        ketebalan: null,
        gramasi: null,
        warna: "",
        setting: null,
        jenisSetting: "",
        harga: null,
        supplier: ""
      });
      if (tableParams.pagination.current > 1) {
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            current: 1,
          }
        });
      } else {
        fetchData();
      }
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=master/bahan")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
      setIsOpenModal(true);
    }
  };

  const onCancel = () => {
    form.resetFields();
    form.setFieldsValue({
      id: null,
      type: selectedType,
      jenis: "",
      ketebalan: null,
      gramasi: null,
      warna: "",
      setting: null,
      jenisSetting: "",
      harga: null,
      supplier: ""
    });
    setSelectedData({
      id: null,
      type: selectedType,
      jenis: "",
      ketebalan: null,
      gramasi: null,
      warna: "",
      setting: null,
      jenisSetting: "",
      harga: null,
      supplier: ""
    });
    setIsOpenModal(false);
  };

  useEffect(() => {
    fetchWarnaBahan();
    fetchSupplier();
    fetchJenisSetting();
    fetchJenisBahan();
  }, []);

  useEffect(() => {
    setFilterOptionSelected("jenis");
    setFilterSearchValue("");
    if (tableParams.pagination.current > 1) {
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          current: 1,
        }
      });
    } else {
      fetchData();
    }
  }, [selectedType]);

  useEffect(() => {
    fetchData();
  }, [
    filterOptionSelected,
    filterSearchValue,
    tableParams.pagination?.current,
    tableParams.pagination?.pageSize,
    tableParams?.sortOrder,
    tableParams?.sortField,
  ]);

  return (
    <Context.Provider value={true}>
      {contextHolder}
      <Segmented 
        options={jenisHargaOption} 
        value={selectedType} 
        size='large'
        onChange={setSelectedType} />
      
      <Flex gap="middle" vertical={false}>
        <Space>
          <Select
            size="large"
            value={filterOptionSelected}
            onChange={handleChangeOption}
            style={{ width: 250 }}
            options={selectedType === "HARGA PABRIK" ? filterOptions : filterOptionsHargaModal}
          />
          <Input 
            size="large"
            style={{ width: 300 }}
            onChange={handleChangeValue}
            value={filterSearchValue} />
        </Space>
        <Space align='end'
          style={{ flexBasis: '100%', flexDirection: 'column-reverse' }}
          >
          <Button 
            size="large" 
            type="primary"
            icon={<PlusOutlined />}
            style={{ width: 200 }}
            iconPosition='start'
            onClick={addBahan}
          >
            Add Bahan
          </Button>
        </Space>
      </Flex>
      <Flex gap="middle" vertical style={{marginTop: '24px'}}>
        <Table
          columns={selectedType === "HARGA PABRIK" ? tableColumnsHargaPabrik : tableColumnsHargaModal}
          rowKey={(record) => record.id}
          dataSource={data}
          pagination={tableParams.pagination}
          loading={{
            spinning: loading,
            indicator: <Spinner className="spinner"/>
          }}
          onChange={handleTableChange}
        />
      </Flex>
      <Modal
        title={selectedData.id === null ? "Add New Bahan" : "Edit Bahan"}
        open={isOpenModal}
        width={700}
        closable={false}
        footer={[]}
      >
        <Form
          form={form}
          initialValues={selectedData}
          name="bahan-form"
          className="bahan-form"
          layout="vertical"
          autoComplete="off"
          autoFocus={true}
          onFinish={onFinish}
        >
          <Space>
            <Form.Item
              layout="vertical"
              label="Jenis Harga"
              name="type"
              style={{width: '294px'}}
              rules={[
                {
                  required: true,
                  message: 'Please select the jenis harga',
                },
              ]}
            >
              <Select 
                disabled
                showSearch
                placeholder="Select a jenis harga"
                options={jenisHargaOption} 
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                optionLabelProp="label"
                size="large" />
            </Form.Item>
            <Form.Item
              layout="vertical"
              label="Jenis Bahan"
              name="jenis"
              style={{width: '350px'}}
              rules={[
                {
                  required: true,
                  message: 'Please select the jenis bahan',
                },
              ]}
            >
              <Select 
                disabled={selectedData.id !== null}
                showSearch
                placeholder="Select a jenis bahan"
                options={jenisBahanOption} 
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                optionLabelProp="label"
                size="large" />
            </Form.Item>
          </Space>
          <Space>
            <Form.Item
              layout="vertical"
              label="Ketebalan"
              name="ketebalan"
              style={{width: '220px'}}
              rules={[
                {
                  required: selectedType === 'HARGA PABRIK',
                  message: 'Please input ketebalan',
                },
              ]}
            >
              <InputNumber addonAfter="s" size='large' min={1} disabled={selectedType === 'HARGA MODAL'}/>
            </Form.Item>
            <Form.Item
              layout="vertical"
              label="Gramasi"
              name="gramasi"
              style={{width: '210px'}}
              rules={[
                {
                  required: selectedType === 'HARGA PABRIK',
                  message: 'Please input gramasi',
                },
              ]}
            >
              <InputNumber size='large' min={1} style={{width: '100%'}} disabled={selectedType === 'HARGA MODAL'}/>
            </Form.Item>
            <Form.Item
              layout="vertical"
              label="Warna Bahan"
              name="warna"
              style={{width: '206px'}}
              rules={[
                {
                  required: selectedType === 'HARGA PABRIK',
                  message: 'Please select warna bahan',
                },
              ]}
            >
              <Select 
                showSearch
                placeholder="Select a warna bahan"
                options={warnaBahanOption} 
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                disabled={selectedType === 'HARGA MODAL'}
                optionLabelProp="label"
                size="large" />
            </Form.Item>
          </Space>
          <Space>
            <Form.Item
              layout="vertical"
              label="Setting Bahan"
              name="setting"
              style={{width: '210px'}}
              rules={[
                {
                  required: selectedType === 'HARGA PABRIK',
                  message: 'Please input setting bahan',
                },
              ]}
            >
              <InputNumber size='large' min={1} style={{width: '100%'}} disabled={selectedType === 'HARGA MODAL'}/>
            </Form.Item>
            <Form.Item
              layout="vertical"
              label="Jenis Setting"
              name="jenisSetting"
              style={{width: '186px'}}
              rules={[
                {
                  required: selectedType === 'HARGA PABRIK',
                  message: 'Please select jenis setting',
                },
              ]}
            >
              <Select 
                showSearch
                placeholder="Select a jenis setting"
                options={jenisSettingOption} 
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                disabled={selectedType === 'HARGA MODAL'}
                optionLabelProp="label"
                size="large" />
            </Form.Item>
            <Form.Item
              layout="vertical"
              label="Harga"
              name="harga"
              style={{width: '240px'}}
              rules={[
                {
                  required: true,
                  message: 'Please input harga',
                },
              ]}
            >
              <Input size='large' addonBefore="Rp" min={1} style={{width: '100%'}}
                onChange={(e) => {
                  let value = e.target.value.replace(/\D/g, '');
                  let formattedNumber = new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(value).replace('Rp', '').trim().split(",")[0];
                  form.setFieldValue('harga', formattedNumber);
                }} />
            </Form.Item>
          </Space>

          <Form.Item
            layout="vertical"
            label="Nama Supplier"
            name="supplier"
            rules={[
              {
                required: selectedType === 'HARGA PABRIK',
                message: 'Please select supplier',
              },
            ]}
          >
            <Select 
              showSearch
              disabled={selectedType === 'HARGA MODAL'}
              placeholder="Select a supplier"
              options={supplierOption} 
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              optionLabelProp="label"
              size="large" />
          </Form.Item>
          <Space
            align='end'
            style={{ flexBasis: '100%', flexDirection: 'column-reverse', width: '100%', marginTop: '28px', }}
          >
            <Form.Item>
              <Button htmlType="button" size="large" style={{marginRight: '8px'}} onClick={onCancel}> 
                Cancel
              </Button>
              <Button type="primary" htmlType="submit" size="large">
                {selectedData.id !== null ? "Edit" : "Add"}
              </Button>
            </Form.Item>
          </Space>
        </Form>
      </Modal>
    </Context.Provider>
  )
}

const Bahan = () => {
  return (
    <MasterLayout
      pageName="Bahan"
      activeMenu="master-bahan" 
      activeParent="master"
      content={Content()}
    />
  )
}

export default Bahan;