import { CallRestApiSecure } from '../../libs/Api';

export const getPageStokToko = (tokoId, payload) => async () => {
  const BASE_URL = process.env.REACT_APP_API_URL;

  try {
    const response = await CallRestApiSecure('post', BASE_URL, 'v1/stok-toko/page/' + tokoId, payload, {}, {}, 'application/json');
    return JSON.parse(response);
  } catch (err) {
    return JSON.parse(err.response.data);
  }
};

export const getStokTokoById = (id) => async () => {
  const BASE_URL = process.env.REACT_APP_API_URL;

  try {
    const response = await CallRestApiSecure('get', BASE_URL, 'v1/stok-toko/get/' + id, {}, {}, {}, 'application/json');
    return JSON.parse(response);
  } catch (err) {
    return JSON.parse(err.response.data);
  }
};

export const getDropdown = (tipe) => async () => {
  const BASE_URL = process.env.REACT_APP_API_URL;

  try {
    const response = await CallRestApiSecure('get', BASE_URL, 'v1/stok-toko/dropdown/' + tipe, {}, {}, {}, 'application/json');
    return JSON.parse(response);
  } catch (err) {
    return JSON.parse(err.response.data);
  }
};