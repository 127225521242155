import './StokToko.scss';
import React, { useEffect, useState } from 'react';
import * as stokTokoApi from '../../stores/business/StokTokoApi';
import { 
  Space, 
  Modal,
  notification,
  Divider,
  Carousel,
  Image,
} from 'antd';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

const Context = React.createContext({
  name: 'Default',
});

const contentStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%', // Ensure the div takes the full height of the carousel item
};

const StokTokoMobile = () => {
  const BASE_URL = process.env.REACT_APP_API_URL;

  const { stokId } = useParams();
  const dispatch = useDispatch();
  const [kodeGambar, setKodeGambar] = useState('-')
  const [data, setData] = useState({
    id: null,
    merek: "-",
    jenisBahan: "-",
    jenisPakaian: "-",
    namaPakaian: "-",
    totalLusin: null,
    totalPieces: null,
    jenisBahanList: [{
      jenisBahan: "-",
    }],
    stokTokoGambarList: [{
      id: null,
      kodeGambar: null,
      namaGambar: null,
      urlGambar: null,
      totalLusin: null,
      totalPieces: null,
      stokTokoId: null,
      stokTokoGambarDetailList: [{
        id: null,
        stokTokoGambarId: null,
        stokTokoId: null,
        totalLusin: null,
        totalPieces: null,
        ukuran: null,
      }]
    }],
    stokTokoHargaList: [{
      id: null,
      stokTokoId: null,
      ukuran: null,
      hargaJualToko: null,
    }]
  });

  const [size, setSize] = useState([{
    ukuran: "-",
    totalLusin: "-",
    harga: "-"
  }]);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [api, contextHolder] = notification.useNotification();

  const openNotification = (message, description, type) => {
    if (type === "error") {
      api.error({
        duration: 3,
        message: message,
        description: description,
        placement: 'top',
      });
    } else if (type === "success") {
      api.success({
        duration: 3,
        message: message,
        description: description,
        placement: 'top',
      });
    }
  };

  const fetchStokData = async () => {
    const response = await dispatch(stokTokoApi.getStokTokoById(stokId));
    if (response.status === 200) {
      setData(response.data);
      if (response.data.stokTokoGambarList.length > 0) {
        setKodeGambar(response.data.stokTokoGambarList[0].kodeGambar);
        let size = [];

        for (let index = 0; index < response.data.stokTokoHargaList.length; index++) {
          let totalPieces = 0;
          
          for (let j = 0; j < response.data.stokTokoGambarList.length; j++) {
            const gambar = response.data.stokTokoGambarList[j];
            
            if (gambar.kodeGambar === response.data.stokTokoGambarList[0].kodeGambar) {
              for (let k = 0; k < gambar.stokTokoGambarDetailList.length; k++) {
                const detail = gambar.stokTokoGambarDetailList[k];
                
                if (detail.ukuran === response.data.stokTokoHargaList[index].ukuran) {
                  totalPieces += detail.totalPieces;
                }
              }
            }
            
          }

          size.push({
            ukuran: response.data.stokTokoHargaList[index].ukuran,
            hargaJualToko: response.data.stokTokoHargaList[index].hargaJualToko,
            totalLusin: formatToLusin(totalPieces),
          });
        }

        setSize(size);
      }
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    }
  };

  const reassignSize = (idx) => {
    let size = [];

    for (let index = 0; index < data.stokTokoHargaList.length; index++) {
      let totalPieces = 0;
      
      for (let j = 0; j < data.stokTokoGambarList.length; j++) {
        const gambar = data.stokTokoGambarList[j];
        
        if (gambar.kodeGambar === data.stokTokoGambarList[idx].kodeGambar) {
          for (let k = 0; k < gambar.stokTokoGambarDetailList.length; k++) {
            const detail = gambar.stokTokoGambarDetailList[k];
            
            if (detail.ukuran === data.stokTokoHargaList[index].ukuran) {
              totalPieces += detail.totalPieces;
            }
          }
        }
        
      }

      size.push({
        ukuran: data.stokTokoHargaList[index].ukuran,
        hargaJualToko: data.stokTokoHargaList[index].hargaJualToko,
        totalLusin: formatToLusin(totalPieces),
      });
    }

    setSize(size);
  }

  const setRedirectTo = () => {
    window.localStorage.setItem('reason-season-redirect-to', "/stok/toko/mobile/" + stokId);
  }

  const formatToCurrency = (value) => {
    if (value !== null && value !== "-" && value !== undefined) {
      return new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(value).trim().split(",")[0];
    } else {
      return "-";
    }
  }

  const formatToLusin = (value) => {
    let sisa = value % 12;
    return Math.floor(value / 12) + (sisa > 0 ? " + (" + sisa + " pieces)" : "");
  }
  
  useEffect(() => {
    setRedirectTo();
    if (!isMobile) {
      setIsOpenModal(true);
    }
    fetchStokData();
  }, []);

  return (
    <Context.Provider value={true}>
      {contextHolder}

      <Carousel 
        arrows 
        infinite={false}
        afterChange={(current) => {
          setKodeGambar(data.stokTokoGambarList[current]?.kodeGambar);
          reassignSize(current);
        }}
      >
        {
          data.stokTokoGambarList.length > 0 && data.stokTokoGambarList.map(obj => (
            <div 
              key={obj.kodeGambar}
              style={contentStyle}
            >
              <Image 
                height={160}
                style={{objectFit: 'contain'}}
                src={BASE_URL + obj.urlGambar}
              />
            </div>
          ))
        }
      </Carousel>

      <div className='stok-toko-mobile' style={{padding: '20px 24px'}}>

        <label className='title'>Nama Merek</label>
        <h1>{data.merek}</h1>

        <Divider />
        
        <Space>
          <p className='detail-item-key'>Kode Gambar</p>
          <p className='detail-item-value'>{kodeGambar}</p>
        </Space>

        <Divider />

        {
          data.jenisBahanList.length > 0  && 
          <Space>
            <p className='detail-item-key'>Jenis Bahan</p>
            <p className='detail-item-value'>{data.jenisBahanList[0].jenisBahan}</p>
          </Space>
        }
        {
          data.jenisBahanList.length > 1 && data.jenisBahanList.slice(1).map(obj => (
            <Space>
              <p className='detail-item-key'></p>
              <p className='detail-item-value'>{obj.jenisBahan}</p>
            </Space>
          ))
        }

        <Divider />
        
        <Space>
          <p className='detail-item-key'>Jenis Pakaian</p>
          <p className='detail-item-value'>{data.jenisPakaian}</p>
        </Space>

        <Divider />
        
        <Space>
          <p className='detail-item-key'>Nama Pakaian</p>
          <p className='detail-item-value'>{data.namaPakaian}</p>
        </Space>

      </div>

      <Divider />
      {
        size.length > 0 && size.map(obj => (
          <>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
              <h2 style={{ margin: '0px', padding: '0px 20px 0px 24px', flex: '3' }}>{obj.ukuran}</h2>
              <div style={{ display: 'flex', flexDirection: 'column', flex: '5' }}>
                <label style={{ margin: '0px', width: '100%' }}>Jumlah Lusin</label>
                <h3 style={{ margin: '0px', width: '100%', paddingTop: '8px' }}>{obj.totalLusin}</h3>
              </div>
              <h3 style={{ margin: '0px', padding: '0px 20px 0px 24px', flex: '4' }}>{formatToCurrency(obj.hargaJualToko)}</h3>
            </div>
            <Divider />
          </>
        ))
      }

      <Modal
        title=""
        centered
        open={isOpenModal}
        onCancel={() => setIsOpenModal(false)}
        footer={[]}
      >
        <p style={{textAlign:'center', fontSize: '16px'}}>Please open in your mobile phone for better experience</p>
      </Modal>
    </Context.Provider>
  );
}

export default StokTokoMobile;