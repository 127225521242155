import { CallRestApiSecure } from '../../libs/Api';

export const getCustomerPage = (payload) => async () => {
  const BASE_URL = process.env.REACT_APP_API_URL;

  try {
    const response = await CallRestApiSecure('post', BASE_URL, 'v1/customer/page', payload, {}, {}, 'application/json');
    return JSON.parse(response);
  } catch (err) {
    return JSON.parse(err.response.data);
  }
};

export const initCustomer = () => async () => {
  const BASE_URL = process.env.REACT_APP_API_URL;

  try {
    const response = await CallRestApiSecure('post', BASE_URL, 'v1/customer/init', {}, {}, {}, 'application/json');
    return JSON.parse(response);
  } catch (err) {
    return JSON.parse(err.response.data);
  }
};

export const updateCustomer = (customerId, payload) => async () => {
  const BASE_URL = process.env.REACT_APP_API_URL;

  try {
    const response = await CallRestApiSecure('post', BASE_URL, 'v1/customer/update/' + customerId, payload, {}, {}, 'application/json');
    return JSON.parse(response);
  } catch (err) {
    return JSON.parse(err.response.data);
  }
};

export const deleteCustomer = (customerId) => async () => {
  const BASE_URL = process.env.REACT_APP_API_URL;

  try {
    const response = await CallRestApiSecure('delete', BASE_URL, 'v1/customer/delete/'+ customerId, {}, {}, {}, 'application/json');
    return JSON.parse(response);
  } catch (err) {
    return JSON.parse(err.response.data);
  }
};

export const addExpedition = (payload) => async () => {
  const BASE_URL = process.env.REACT_APP_API_URL;

  try {
    const response = await CallRestApiSecure('post', BASE_URL, 'v1/customer/expedition', payload, {}, {}, 'application/json');
    return JSON.parse(response);
  } catch (err) {
    return JSON.parse(err.response.data);
  }
};

export const removeExpedition = (payload) => async () => {
  const BASE_URL = process.env.REACT_APP_API_URL;

  try {
    const response = await CallRestApiSecure('delete', BASE_URL, 'v1/customer/expedition', payload, {}, {}, 'application/json');
    return JSON.parse(response);
  } catch (err) {
    return JSON.parse(err.response.data);
  }
};

export const getExpedition = (customerId, payload) => async () => {
  const BASE_URL = process.env.REACT_APP_API_URL;

  try {
    const response = await CallRestApiSecure('post', BASE_URL, 'v1/customer/expedition/' + customerId, payload, {}, {}, 'application/json');
    return JSON.parse(response);
  } catch (err) {
    return JSON.parse(err.response.data);
  }
};

export const getTokoList = () => async () => {
  const BASE_URL = process.env.REACT_APP_API_URL;

  try {
    const response = await CallRestApiSecure('get', BASE_URL, 'v1/customer/list/toko', {}, {}, {}, 'application/json');
    return JSON.parse(response);
  } catch (err) {
    return JSON.parse(err.response.data);
  }
};

export const getTokoListWithId = () => async () => {
  const BASE_URL = process.env.REACT_APP_API_URL;

  try {
    const response = await CallRestApiSecure('get', BASE_URL, 'v1/customer/list/toko-with-id', {}, {}, {}, 'application/json');
    return JSON.parse(response);
  } catch (err) {
    return JSON.parse(err.response.data);
  }
};

export const getDetailAddressByCustomerId = (customerId) => async () => {
  const BASE_URL = process.env.REACT_APP_API_URL;

  try {
    const response = await CallRestApiSecure('get', BASE_URL, 'v1/customer/detail/address/' + customerId, {}, {}, {}, 'application/json');
    return JSON.parse(response);
  } catch (err) {
    return JSON.parse(err.response.data);
  }
};