import './LaporanPiutang.scss';
import MasterLayout from '../../components/MasterLayout';
import Spinner from '../../components/Spinner';
import React, { useEffect, useState } from 'react';
import { 
  Flex, 
  Select, 
  Space, 
  Input, 
  Table,
  notification,
} from 'antd';
import { useDispatch } from 'react-redux';
import * as laporanPiutangApi from '../../stores/business/LaporanPiutangApi';

const Context = React.createContext({
  name: 'Default',
});

const Content = () => {
  const [api, contextHolder] = notification.useNotification();
  const dispatch = useDispatch();

  const openNotification = (message, description, type) => {
    if (type === "error") {
      api.error({
        duration: 3,
        message: message,
        description: description,
        placement: 'top',
      });
    } else if (type === "success") {
      api.success({
        duration: 3,
        message: message,
        description: description,
        placement: 'top',
      });
    }
  };
  const [data, setData] = useState();
  const [filterOptionSelected, setFilterOptionSelected] = useState('toko');
  const [filterSearchValue, setFilterSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
    sortField: "toko",
    sortOrder: "ascend",
  });

  function convertToIndonesianDate(dateString) {
    const months = [
        "Januari", "Februari", "Maret", "April", "Mei", "Juni",
        "Juli", "Agustus", "September", "Oktober", "November", "Desember"
    ];

    const date = new Date(dateString);
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
  }

  const tableColumns = [
    {
      title: 'Nama Toko',
      dataIndex: 'toko',
      key: 'toko',
      sorter: true,
    },
    {
      title: 'Jumlah Sub Total',
      dataIndex: 'total',
      key: 'total',
      sorter: true,
      render: (_, record) => (
        new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(record.total).trim().split(",")[0]
      ),
    },
  ];

  const tableColumnsDetail = [
    {
      title: 'No. Invoice',
      dataIndex: 'noInvoice',
      key: 'noInvoice',
    },
    {
      title: 'Tanggal Invoice',
      dataIndex: 'tanggalInvoice',
      key: 'tanggalInvoice',
      render: (_, record) => (
        convertToIndonesianDate(record.tanggalInvoice)
      ),
    },
    {
      title: 'Sub Total',
      dataIndex: 'totalPrice',
      key: 'totalPrice',
      render: (_, record) => (
        new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(record.totalPrice).trim().split(",")[0]
      ),
    },
    {
      title: 'Source',
      dataIndex: 'source',
      key: 'source',
    },
  ];

  const filterOptions = [
    {value: 'toko', label: 'Nama Toko'},
  ];

  const handleChangeOption = (value) => {
    setFilterOptionSelected(value);
    setFilterSearchValue('');
  };

  const handleChangeValue = (e) => {
    setFilterSearchValue(e.target.value);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    var sortField = sorter.field;
    var sortOrder = sorter.order;
    setTableParams({
      pagination,
      filters,
      sortOrder: sortOrder,
      sortField: sortField,
    });
    
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  const fetchData = async () => {
    setLoading(true);

    var sortField = tableParams.sortField === undefined ? "toko" : tableParams.sortField;
    var sortOrder = tableParams.sortOrder === "descend" ? "desc" : "asc"

    if (tableParams.sortOrder === undefined || tableParams.sortOrder === null) {
      sortOrder = "asc";
      sortField = "toko";
    }

    const response = await dispatch(laporanPiutangApi.getPageAkumulasiPiutang({
      "page": tableParams.pagination.current,
      "size": tableParams.pagination.pageSize,
      "sort": [
        sortField + " " + sortOrder,
      ],
      "search": [
        {
          field: filterOptionSelected,
          value: filterSearchValue
        }
      ]
    }));
    if (response.status === 200) {
      setData(response.data.contents);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: response.data.totalData,
        }
      });
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
    setLoading(false);
  };

  const expandedRowRender = (record) => {
    return (
      <Table columns={tableColumnsDetail} dataSource={record.invoiceList} pagination={false} />
    );
  }

  useEffect(() => {
    fetchData();
  }, [
    filterOptionSelected,
    filterSearchValue,
    tableParams.pagination?.current,
    tableParams.pagination?.pageSize,
    tableParams?.sortOrder,
    tableParams?.sortField,
  ]);

  return (
    <Context.Provider value={true}>
      {contextHolder}
      <Flex gap="middle" vertical={false}>
        <Space>
          <Select
            size="large"
            value={filterOptionSelected}
            onChange={handleChangeOption}
            style={{ width: 250 }}
            options={filterOptions}
          />
          <Input 
            size="large"
            style={{ width: 300 }}
            onChange={handleChangeValue}
            value={filterSearchValue} />
        </Space>
      </Flex>
      <Flex gap="middle" vertical>
        <Table
          columns={tableColumns}
          rowKey={(record) => record.toko}
          dataSource={data}
          pagination={tableParams.pagination}
          loading={{
            spinning: loading,
            indicator: <Spinner className="spinner"/>
          }}
          onChange={handleTableChange}
          expandable={{
            expandedRowRender,
          }}
        />
      </Flex>
    </Context.Provider>
  );
}

const AkumulasiPiutang = () => {
  return (
    <MasterLayout
      pageName={"Laporan Akumulasi Piutang"}
      activeMenu={"laporan-akumulasi-piutang"} 
      activeParent="laporan-piutang"
      content={Content()}
    />
  )
}

export default AkumulasiPiutang;