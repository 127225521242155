import './StokToko.scss';
import MasterLayout from '../../components/MasterLayout';
import Spinner from '../../components/Spinner';
import React, { useEffect, useState } from 'react';
import { 
  Flex, 
  Select, 
  Space, 
  Input, 
  Button,
  Table,
  Modal,
  Form,
  notification,
  QRCode,
  Image,
  Divider,
} from 'antd';
import {
  EyeOutlined,
  PrinterOutlined,
  QrcodeOutlined,
  PictureOutlined,
} from '@ant-design/icons';
import * as stokTokoApi from '../../stores/business/StokTokoApi';
import * as pakaianApi from '../../stores/business/PakaianApi';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import noImage from '../../assets/no-image.png';

const Context = React.createContext({
  name: 'Default',
});

const Content = () => {
  const BASE_URL = process.env.REACT_APP_API_URL;
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const [formGambar] = Form.useForm();

  const [api, contextHolder] = notification.useNotification();

  const openNotification = (message, description, type) => {
    if (type === "error") {
      api.error({
        duration: 3,
        message: message,
        description: description,
        placement: 'top',
      });
    } else if (type === "success") {
      api.success({
        duration: 3,
        message: message,
        description: description,
        placement: 'top',
      });
    }
  };

  const [loading, setLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenModalGambar, setIsOpenModalGambar] = useState(false);
  const [isOpenModalQr, setIsOpenModalQr] = useState(false);
  const [data, setData] = useState();
  const [qrData, setQrData] = useState();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [filterOptionSelected, setFilterOptionSelected] = useState('merek');
  const [filterSearchValue, setFilterSearchValue] = useState('');
  const [tokoIdSelected, setTokoIdSelected] = useState();
  const [sizeGroupOpt, setSizeGroupOpt] = useState([]);
  const [sourceTokoOpt, setSourceTokoOpt] = useState([]);
  const [previewImages, setPreviewImages] = useState({
    items: [],
    visible: false,
  });
  const [selectedData, setSelectedData] = useState({
    id: null,
    merek: null,
    jenisBahan: null,
    jenisPakaian: null,
    namaPakaian: null,
    totalLusin: null,
    totalPieces: null,
    jenisBahanList: [{
      jenisBahan: null,
    }],
    stokTokoGambarList: [{
      id: null,
      kodeGambar: null,
      namaGambar: null,
      urlGambar: null,
      totalLusin: null,
      totalPieces: null,
      stokTokoId: null,
      stokTokoGambarDetailList: [{
        id: null,
        stokTokoGambarId: null,
        stokTokoId: null,
        totalLusin: null,
        totalPieces: null,
        ukuran: null,
      }]
    }],
    stokTokoHargaList: [{
      id: null,
      stokTokoId: null,
      ukuran: null,
      hargaJualToko: null,
    }]
  });
  const [selectedDataGambar, setSelectedDataGambar] = useState({
    id: null,
    kodeGambar: null,
    namaGambar: null,
    urlGambar: null,
    totalLusin: null,
    totalPieces: null,
    stokTokoId: null,
    stokTokoGambarDetailList: [{
      id: null,
      stokTokoGambarId: null,
      stokTokoId: null,
      totalLusin: null,
      totalPieces: null,
      ukuran: null,
    }]
  });

  const filterOptions = [
    {value: 'merek', label: 'Nama Merek'},
    {value: 'jenisBahan', label: 'Jenis Bahan'},
    {value: 'jenisPakaian', label: 'Jenis Pakaian'},
    {value: 'namaPakaian', label: 'Nama Pakaian'},
  ];
  
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
    sortField: "updatedAt",
    sortOrder: "descend",
  });

  const tableColumns = [
    {
      title: 'Nama Merek',
      dataIndex: 'merek',
      key: 'merek',
      sorter: true,
    },
    {
      title: 'Jenis Bahan',
      dataIndex: 'jenisBahan',
      key: 'jenisBahan',
      sorter: true,
    },
    {
      title: 'Jenis Pakaian',
      dataIndex: 'jenisPakaian',
      key: 'jenisPakaian',
      sorter: true,
    },
    {
      title: 'Nama Pakaian',
      dataIndex: 'namaPakaian',
      key: 'namaPakaian',
      sorter: true,
    },
    {
      title: 'Total Keseluruhan Lusin',
      dataIndex: 'totalLusin',
      key: 'totalLusin',
      sorter: true,
    },
    {
      title: 'Total Keseluruhan Piece',
      dataIndex: 'totalPieces',
      key: 'totalPieces',
      sorter: true,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button shape="circle" icon={<QrcodeOutlined />} onClick={() => {showQr(record);}}/>
          {record.images?.length > 0 && <Button shape="circle" icon={<PictureOutlined />} onClick={() => {openPreviewImages(record);}}/>}
          <Button shape="circle" icon={<EyeOutlined />} onClick={() => {viewStok(record);}}/>
        </Space>
      ),
    },
  ];

  const tableColumnsSize = [
    {
      title: 'Ukuran',
      dataIndex: 'ukuran',
      key: 'ukuran',
    },
    {
      title: 'Harga Jual Toko',
      dataIndex: 'hargaJualToko',
      key: 'hargaJualToko',
      render: (_, record) => (
        record.hargaJualToko === null || record.hargaJualToko === "" ? "" : 
        new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(record.hargaJualToko).trim().split(",")[0]
      ),
    },
  ];

  const tableColumnsBahan = [
    {
      title: 'Jenis Bahan',
      dataIndex: 'jenisBahan',
      key: 'jenisBahan',
    },
  ];

  const tableColumnsGambar = [
    {
      title: 'Kode Gambar',
      dataIndex: 'kodeGambar',
      key: 'kodeGambar',
      render: (_, record) => (
        record.kodeGambar === null ? "NO IMAGE" : record.kodeGambar
      ),
    },
    {
      title: 'Nama Gambar',
      dataIndex: 'namaGambar',
      key: 'namaGambar',
      render: (_, record) => (
        record.namaGambar === null ? "NO IMAGE" : record.namaGambar
      ),
    },
    {
      title: 'Ukuran',
      dataIndex: 'sizeGroup',
      key: 'sizeGroup',
      sorter: true,
    },
    {
      title: 'Total Jumlah Lusin',
      dataIndex: 'totalLusin',
      key: 'totalLusin',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button shape="circle" icon={<EyeOutlined />} onClick={() => {viewGambar(record)}}/>
        </Space>
      ),
    },
  ];

  const handleChangeOptionToko = (value) => {
    setTokoIdSelected(value);
  };

  const handleChangeOption = (value) => {
    setFilterOptionSelected(value);
  };

  const handleChangeValue = (e) => {
    setFilterSearchValue(e.target.value);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    var sortField = sorter.field;
    var sortOrder = sorter.order;
    setTableParams({
      pagination,
      filters,
      sortOrder: sortOrder,
      sortField: sortField,
    });
    
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  const onSelectTableChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectTableChange,
  };

  const resetForm = () => {
    form.resetFields();
    setSelectedData({
      id: null,
      merek: null,
      jenisBahan: null,
      jenisPakaian: null,
      namaPakaian: null,
      totalLusin: null,
      totalPieces: null,
    });
    form.setFieldsValue({
      id: null,
      merek: null,
      jenisBahan: null,
      jenisPakaian: null,
      namaPakaian: null,
      totalLusin: null,
      totalPieces: null,
    });
  }

  const showQr = (record) => {
    let baseUrlFe = window.location.href;
    setQrData(baseUrlFe + "/mobile/" + record.id);
    setSelectedData(record);
    form.setFieldsValue(record);

    setIsOpenModalQr(true);
  }

  const print = () => {
    let baseUrlFe = window.location.href;
    let urlFe = baseUrlFe + "/mobile/";

    var ids = selectedRowKeys.map(obj => obj).join(',');
    let encodedId = btoa(ids);
    setSelectedRowKeys([]);
    window.open(BASE_URL + "/v1/generate/qr/stok-toko?url=" + urlFe + "&ids=" + encodedId);
  };

  const onPrint = () => {
    let baseUrlFe = window.location.href;
    let urlFe = baseUrlFe + "/mobile/";
    let encodedId = btoa(selectedData.id);
    window.open(BASE_URL + "/v1/generate/qr/stok-toko?url=" + urlFe + "&ids=" + encodedId);
  }

  const onClose = () => {
    resetForm();
    setQrData();
    setIsOpenModalQr(false);
  }

  const onCancel = () => {
    resetForm();
    setQrData();
    setIsOpenModal(false);
  }

  const resetFormGambar = () => {
    formGambar.resetFields();
    setSelectedDataGambar({
      id: null,
      kodeGambar: null,
      namaGambar: null,
      urlGambar: null,
      totalLusin: null,
      totalPieces: null,
      stokTokoId: null,
      stokTokoGambarDetailList: [{
        id: null,
        stokTokoGambarId: null,
        stokTokoId: null,
        totalLusin: null,
        totalPieces: null,
        ukuran: null,
      }]
    });
    formGambar.setFieldsValue({
      id: null,
      kodeGambar: null,
      namaGambar: null,
      urlGambar: null,
      totalLusin: null,
      totalPieces: null,
      stokTokoId: null,
      stokTokoGambarDetailList: [{
        id: null,
        stokTokoGambarId: null,
        stokTokoId: null,
        totalLusin: null,
        totalPieces: null,
        ukuran: null,
      }]
    });
  }

  const openPreviewImages = (record) => {
    let urls = [];
    record.images.map(obj => {
      let result = BASE_URL + obj;
      urls.push(result);
      return obj;
    });
    setPreviewImages({
      ...previewImages,
      visible: true,
      items: urls,
    });
  }

  const viewGambar = (record) => {
    setSelectedDataGambar(record);
    formGambar.setFieldsValue(record);
    setIsOpenModalGambar(true);
  }

  const viewStok = (record) => {
    let baseUrlFe = window.location.href;
    setQrData(baseUrlFe + "/mobile/" + record.id);
    fetchDataById(record.id);
    fetchSizeGroup(record.jenisPakaian, record.namaPakaian)
    setIsOpenModal(true);
  }

  const fetchData = async () => {
    if (tokoIdSelected === "" || tokoIdSelected === undefined || tokoIdSelected === null) {
      return;
    }
    setLoading(true);

    var sortField = tableParams.sortField === undefined ? "updatedAt" : tableParams.sortField;
    var sortOrder = tableParams.sortOrder === "ascend" ? "asc" : "desc";

    if (tableParams.sortOrder === undefined || tableParams.sortOrder === null) {
      sortOrder = "desc";
      sortField = "updatedAt";
    }

    const response = await dispatch(stokTokoApi.getPageStokToko(tokoIdSelected, {
      "page": tableParams.pagination.current,
      "size": tableParams.pagination.pageSize,
      "sort": [
        sortField + " " + sortOrder,
      ],
      "search": [
        {
          field: filterOptionSelected,
          value: filterSearchValue
        }
      ]
    }));
    if (response.status === 200) {
      setData(response.data.contents);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: response.data.totalData,
        }
      });
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
    setLoading(false);
  };

  const fetchDataById = async (id) => {
    const response = await dispatch(stokTokoApi.getStokTokoById(id));
    if (response.status === 200) {
      setSelectedData(response.data);
      form.setFieldsValue(response.data);
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
  };

  const fetchSizeGroup = async (jenis, nama) => {
    const response = await dispatch(pakaianApi.getSizeGroupList(jenis, nama));
    if (response.status === 200) {
      setSizeGroupOpt(response.data);
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
  };

  const fetchTokoSource = async () => {
    const response = await dispatch(stokTokoApi.getDropdown('toko'));
    if (response.status === 200) {
      setSourceTokoOpt(response.data);
      if (response.data.length > 0 && (tokoIdSelected === "" || tokoIdSelected === null || tokoIdSelected === undefined)) {
        setTokoIdSelected(response.data[0].value);
      }
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
  };

  useEffect(() => {
    fetchData();
  }, [
    filterOptionSelected,
    filterSearchValue,
    tableParams.pagination?.current,
    tableParams.pagination?.pageSize,
    tableParams?.sortOrder,
    tableParams?.sortField,
    tokoIdSelected,
  ]);

  useEffect(() => {
    fetchTokoSource();
  }, []);

  return (
    <Context.Provider value={true}>
      {contextHolder}

      <Form.Item
        layout="vertical"
        label=""
        style={{width: '100%'}}
      >
        <Select
          size="large"
          placeholder="Pilih Toko"
          value={tokoIdSelected}
          defaultValue={tokoIdSelected}
          onChange={handleChangeOptionToko}
          style={{ width: '100%' }}
          options={sourceTokoOpt}
        />
      </Form.Item>
      
      <Flex gap="middle" vertical={false}>
        <Space>
          <Select
            size="large"
            value={filterOptionSelected}
            onChange={handleChangeOption}
            style={{ width: 250 }}
            options={filterOptions}
          />
          <Input 
            size="large"
            style={{ width: 300 }}
            onChange={handleChangeValue}
            value={filterSearchValue} />
        </Space>
        <Space align='end' style={{ justifyContent: 'end', width: '100%' }}>
          <Button 
            disabled={selectedRowKeys.length === 0}
            size="large" 
            type="primary"
            icon={<PrinterOutlined />}
            iconPosition='start'
            onClick={print}
          >
            Print
          </Button>
        </Space>
      </Flex>
      <Flex gap="middle" vertical style={{marginTop: '24px'}}>
        <Table
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          columns={tableColumns}
          rowKey={(record) => record.id}
          dataSource={data}
          pagination={tableParams.pagination}
          loading={{
            spinning: loading,
            indicator: <Spinner className="spinner"/>
          }}
          onChange={handleTableChange}
        />
      </Flex>

      <Modal
        title="Preview QR Code"
        open={isOpenModalQr}
        width={500}
        closable={false}
        footer={[]}
      >
        <Space id="qr" direction="vertical"  style={{ justifyContent: 'end', width: '100%' }}>
          <Flex gap="middle" vertical>
            <label style={{fontSize: '24px', fontWeight:'bold', textAlign: 'center'}}>Stok - Toko</label>
            <label style={{fontSize: '24px', fontWeight:'bold', textAlign: 'center'}}>{selectedData.merek}</label>
            <label style={{fontSize: '20px', fontWeight:'bold', textAlign: 'center'}}>{selectedData.jenisPakaian?.toUpperCase()} - {selectedData.namaPakaian}</label>
            
            <QRCode
              type='svg'
              style={{
                width: '352px',
                height: '352px',
                margin: 'auto',
              }}
              errorLevel="H"
              value={qrData}
            />

            <label style={{marginBottom:"16px", fontWeight:'bold', textAlign:'center'}}>Dicetak Oleh : CV. Ian Putra Reason</label>
          </Flex>
        </Space>

        <Space align='end' style={{ justifyContent: 'end', width: '100%' }}>
          <Button htmlType="button" size="large" style={{marginRight: '8px'}} onClick={onClose}> 
            Close
          </Button>
          <Button type="primary" size="large" onClick={onPrint}>
            Print
          </Button>
        </Space>
      </Modal>

      <Image.PreviewGroup
        preview={{
          visible: previewImages.visible,
          onVisibleChange: (value) => {
            setPreviewImages({
              ...previewImages,
              visible: value,
            });
          },
        }}
        items={previewImages.items}
      />

      <Modal
        title="View Stok Detail"
        open={isOpenModal}
        width={900}
        closable={false}
        footer={[]}
      >
        <Form
          form={form}
          initialValues={selectedData}
          name="main-form"
          className="main-form"
          layout="vertical"
          autoComplete="off"
          autoFocus={true}
        >
          <Form.Item
            layout="vertical"
            label="Nama Merek"
            name="merek"
          >
            <Input size="large" disabled/>
          </Form.Item>

          <Space>
            <Form.Item
              layout="vertical"
              label="Jenis Pakaian"
              name="jenisPakaian"
              style={{width: '300px'}}
            >
              <Input size="large" disabled/>
            </Form.Item>

            <Form.Item
              layout="vertical"
              label="Nama Pakaian"
              name="namaPakaian"
              style={{width: '545px'}}
            >
              <Input size="large" disabled/>
            </Form.Item>
          </Space>

          <span>
            <label style={{fontSize:'20px', fontWeight:'bold', marginTop:'16px'}}>Kain</label>
          </span>

          <Flex gap="middle" vertical style={{marginTop: '16px', marginBottom:'16px'}}>
            <Table
              bordered
              columns={tableColumnsBahan}
              rowKey={(record) => record.id}
              dataSource={selectedData.jenisBahanList}
              pagination={false}
              loading={{
                spinning: loading,
                indicator: <Spinner className="spinner"/>
              }}
            />
          </Flex>

          <Flex gap="middle" vertical style={{marginTop: '16px'}}>
            <Table
              bordered
              columns={tableColumnsGambar}
              rowKey={(record) => record.id}
              dataSource={selectedData.stokTokoGambarList}
              pagination={false}
              loading={{
                spinning: loading,
                indicator: <Spinner className="spinner"/>
              }}
            />
          </Flex>

          <h3 style={{textAlign: 'center'}}>Total Quantity Setelah Dihitung</h3>

          <Divider />
        
          <Space>
            <Form.Item
              layout="vertical"
              label="Total Keseluruhan Lusin"
              name="totalLusin"
              style={{width: '545px'}}
            >
              <Input size="large" disabled/>
            </Form.Item>

            <Form.Item
              layout="vertical"
              label="Total Keseluruhan Piece"
              name="totalPieces"
              style={{width: '300px'}}
            >
              <Input size="large" disabled/>
            </Form.Item>
          </Space>

          <span>
            <label style={{fontSize:'20px', fontWeight:'bold'}}>Harga Toko</label>
          </span>

          <Flex gap="middle" vertical style={{marginTop: '16px'}}>
            <Table
              bordered
              columns={tableColumnsSize}
              rowKey={(record) => record.id}
              dataSource={selectedData.stokTokoHargaList}
              pagination={false}
              loading={{
                spinning: loading,
                indicator: <Spinner className="spinner"/>
              }}
            />
          </Flex>

        </Form>

        <Divider />

        <Space id="qr" direction="vertical"  style={{ justifyContent: 'end', width: '100%' }}>
          <Flex gap="middle" vertical>
            <label style={{fontSize: '24px', fontWeight:'bold', textAlign: 'center'}}>Stok - Toko</label>
            <label style={{fontSize: '24px', fontWeight:'bold', textAlign: 'center'}}>{selectedData.merek}</label>
            <label style={{fontSize: '20px', fontWeight:'bold', textAlign: 'center'}}>{selectedData.jenisPakaian?.toUpperCase()} - {selectedData.namaPakaian}</label>
            
            <QRCode
              type='svg'
              style={{
                width: '352px',
                height: '352px',
                margin: 'auto',
              }}
              errorLevel="H"
              value={qrData}
            />

            <label style={{marginBottom:"16px", fontWeight:'bold', textAlign:'center'}}>Dicetak Oleh : CV. Ian Putra Reason</label>
          </Flex>
        </Space>

        <Space align='end' style={{ justifyContent: 'end', width: '100%' }}>
          <Button htmlType="button" size="large" style={{marginRight: '8px'}} onClick={onCancel}> 
            Close
          </Button>
        </Space>
      </Modal>

      <Modal
        title="View Detail"
        open={isOpenModalGambar}
        width={700}
        closable={false}
        footer={[]}
      >
        <Form
          form={formGambar}
          name="gambar-form"
          className="gambar-form"
          layout="vertical"
          autoComplete="off"
        >

          <Form.Item
            layout="vertical"
            label="Kode Gambar"
            name="kodeGambar"
          >
            <Input size="large" placeholder='Kode gambar' disabled />
          </Form.Item>

          <Form.Item
            layout="vertical"
            label="Ukuran"
            name="sizeGroup"
            rules={[
              {
                required: true,
                message: 'Pilih ukuran',
              },
            ]}
          >
            <Select 
              showSearch
              disabled
              placeholder="Pilih ukuran"
              options={sizeGroupOpt} 
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              optionLabelProp="label"
              size="large" />
          </Form.Item>

          <Space>
            <Space style={{marginBottom: '16px', border:'1px solid #b8ded8', borderRadius: '8px'}}>
              <Image
                alt={selectedDataGambar.namaGambar === null ? "no-image": selectedDataGambar.namaGambar}
                width={284}
                height={284}
                style={{objectFit: 'contain', padding: '12px'}}
                src={selectedDataGambar.urlGambar === null ? noImage : BASE_URL + selectedDataGambar.urlGambar}
              />
            </Space>
            <Space style={{display: 'block'}}>
            {
              selectedDataGambar.stokTokoGambarDetailList.map(obj => (
                <Space key={obj.id}>
                  <Form.Item
                    layout="vertical"
                    label=""
                    name={"size" +obj.id}
                    initialValue={obj.ukuran}
                    style={{width: '100px'}}
                  >
                    <Input size="large" placeholder='Ukuran' disabled />
                  </Form.Item>
                  <Form.Item
                    layout="vertical"
                    label=""
                    name={"jumlah"+obj.id}
                    initialValue={obj.totalLusin}
                    style={{width: '250px'}}
                  >
                    <Input 
                      style={{width: '100%'}} 
                      size="large" 
                      placeholder='Total jumlah Lusin' 
                      disabled
                    />
                  </Form.Item>
                </Space>
              ))
            }
            </Space>
          </Space>

          <Form.Item
            layout="vertical"
            label="Total Jumlah Lusin"
            name="totalLusin"
          >
            <Input style={{width: '100%'}} size="large" placeholder='Total jumlah Lusin' disabled />
          </Form.Item>

          <Space
            align='end'
            style={{ flexBasis: '100%', flexDirection: 'column-reverse', width: '100%', marginTop: '28px', }}
          >
            <Form.Item>
              <Button htmlType="button" size="large" style={{marginRight: '8px'}} 
              onClick={() => {
                setIsOpenModalGambar(false);
                resetFormGambar();
              }}> 
                Close
              </Button>
            </Form.Item>
          </Space>    
        </Form>    
      </Modal>
    </Context.Provider>
  )

}

const StokToko = () => {
  return (
    <MasterLayout
      pageName="Toko"
      activeMenu="stok-toko" 
      activeParent="stok"
      content={Content()}
    />
  )
}

export default StokToko;