import './SalesInvoice.scss';
import MasterLayout from '../../components/MasterLayout';
import Spinner from '../../components/Spinner';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { 
  Flex, 
  Select, 
  Space, 
  Input, 
  InputNumber,
  DatePicker,
  Button,
  Table,
  Modal,
  Form,
  notification,
  Divider,
  Card,
  Image,
  Checkbox,
} from 'antd';
import {
  PlusOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  ExclamationCircleFilled,
  PrinterOutlined,
} from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import * as salesInvoiceApi from '../../stores/business/SalesInvoiceApi';
import * as customerApi from '../../stores/business/CustomerApi';
import * as merekApi from '../../stores/business/MerekApi';
import * as stokTokoApi from '../../stores/business/StokTokoApi';
import noImage from '../../assets/no-image.png';

const Context = React.createContext({
  name: 'Default',
});

const { confirm } = Modal;

function convertToIndonesianDate(dateString) {
  if (dateString === null) {
    return "";
  }

  const months = [
      "Januari", "Februari", "Maret", "April", "Mei", "Juni",
      "Juli", "Agustus", "September", "Oktober", "November", "Desember"
  ];

  const date = new Date(dateString);
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  return `${day} ${month} ${year}`;
}

const { Meta } = Card;

const Content = () => {
  const BASE_URL = process.env.REACT_APP_API_URL;
  const [api, contextHolder] = notification.useNotification();

  const [form] = Form.useForm();
  const [formGambar] = Form.useForm();
  const [formGambarDetail] = Form.useForm();
  const dispatch = useDispatch();

  const openNotification = (message, description, type) => {
    if (type === "error") {
      api.error({
        duration: 3,
        message: message,
        description: description,
        placement: 'top',
      });
    } else if (type === "success") {
      api.success({
        duration: 3,
        message: message,
        description: description,
        placement: 'top',
      });
    }
  };

  const [loading, setLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenModalPilihGambar, setIsOpenModalPilihGambar] = useState(false);
  const [isOpenModalGambarDetail, setIsOpenModalGambarDetail] = useState(false);
  const [forceReloadSize, setForceReloadSize] = useState(false);
  const [data, setData] = useState();
  const [dataGambar, setDataGambar] = useState();
  const [filterOptionSelected, setFilterOptionSelected] = useState('noInvoice');
  const [filterSearchValue, setFilterSearchValue] = useState('');
  const [filterSearchDateValue, setFilterSearchDateValue] = useState('');
  const [selectedData, setSelectedData] = useState({
    id: null,
    sourceTokoId: null,
    tipe: null,
    noInvoice: null,
    logoId: null,
    logoUrl: null,
    tanggalInvoice: null,
    tokoId: null,
    namaToko: null,
    tanggalKirim: null,
    alamat: null,
    discountType: null,
    discountAmount: null,
    totalLusin: null,
    totalPieces: null,
    totalPrice: null,
    description: null,
    paymentType: null,
    acceptedBy: null,
    acceptedByDate: null,
    knownBy: null,
    knownByDate: null,
    status: null,
  });
  const [selectedGambarOpt, setSelectedGambarOpt] = useState([]);
  const [gambarSearchParam, setGambarSearchParam] = useState({
    merek: '',
    jenisPakaian: '',
    namaPakaian: '',
    jenisBahan: '',
    sizeGroup: '',
    kodeGambar: ''
  });

  const [selectedDataGambar, setSelectedDataGambar] = useState({
    id: null,
    salesInvoiceId: null,
    stokGambarId: null,
    kodeGambar: null,
    urlGambar: null,
    merek: null,
    jenisPakaian: null,
    namaPakaian: null,
    jenisBahan: null,
    sizeGroup: null,
    totalLusin: null,
    totalPiece: null,
    totalPrice: null,
    sizes: [{
      id: null,
      salesInvoiceId: null,
      salesInvoiceImageId: null,
      stokGambarDetailId: null,
      ukuran: null,
      totalLusin: null,
      totalPiece: null,
      hargaJualToko: null,
      availableStok: null,
      exceedLimit: null
    }]
  });

  const [merekOpt, setMerekOpt] = useState([]);
  const [tokoOpt, setTokoOpt] = useState([]);
  const [sourceTokoOpt, setSourceTokoOpt] = useState([]);

  const [merekStokOpt, setMerekStokOpt] = useState([]);
  const [jenisPakaianStokOpt, setJenisPakaianStokOpt] = useState([]);
  const [namaPakaianStokOpt, setNamaPakaianStokOpt] = useState([]);
  const [jenisBahanStokOpt, setJenisBahanStokOpt] = useState([]);
  const [sizeGroupStokOpt, setSizeGroupMerekStokOpt] = useState([]);
  const [gambarOpt, setGambarOpt] = useState([]);

  const discountTypeOpt = [
    {value: '%', label: 'Percent (%)'},
    {value: 'Rp', label: 'Rupiah (Rp)'},
  ];

  const paymentType = [
    {value: 'Cash', label: 'Cash'},
    {value: 'Kredit', label: 'Kredit'},
  ];
  
  const salesInvoiceType = [
    {value: 1, label: 'Gudang ke Toko'},
    {value: 2, label: 'Gudang ke Customer'},
    {value: 3, label: 'Toko ke Customer'},
  ];

  const filterOptions = [
    {value: 'noInvoice', label: 'Nomor Invoice'},
    {value: 'tanggalInvoice', label: 'Tanggal Invoice'},
    {value: 'namaToko', label: 'Nama Toko'},
    {value: 'tanggalKirim', label: 'Tanggal Kirim'},
  ];
  
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
    sortField: "updatedAt",
    sortOrder: "descend",
  });
  
  const [tableParamsGambar, setTableParamsGambar] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
    sortField: "id",
    sortOrder: "descend",
  });

  const tableColumns = [
    {
      title: 'Nomor Invoice',
      dataIndex: 'noInvoice',
      key: 'noInvoice',
      sorter: true,
    },
    {
      title: 'Tanggal Invoice',
      dataIndex: 'tanggalInvoice',
      key: 'tanggalInvoice',
      sorter: true,
      render: (_, record) => (
        convertToIndonesianDate(record.tanggalInvoice)
      ),
    },
    {
      title: 'Nama Toko',
      dataIndex: 'namaToko',
      key: 'namaToko',
      sorter: true,
    },
    {
      title: 'Tanggal Kirim',
      dataIndex: 'tanggalKirim',
      key: 'tanggalKirim',
      sorter: true,
      render: (_, record) => (
        convertToIndonesianDate(record.tanggalKirim)
      ),
    },
    {
      title: 'Sub Total',
      dataIndex: 'totalPrice',
      key: 'totalPrice',
      sorter: true,
      render: (_, record) => (
        new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(record.totalPrice).trim().split(",")[0]
      ),
    },
    {
      title: 'Pembayaran',
      dataIndex: 'paymentType',
      key: 'paymentType',
      sorter: true,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          {record.status === "Done" && <Button shape="circle" icon={<PrinterOutlined />} onClick={() => {print(record)}}/>}
          <Button shape="circle" icon={record.status !== "Done" ? <EditOutlined /> : <EyeOutlined />} onClick={() => {onClickEdit(record)}}/>
          <Button shape="circle" icon={<DeleteOutlined />} onClick={() => {showDeleteConfirm(record);}}/>
        </Space>
      ),
    },
  ];

  const tableColumnsGambar = [
    {
      title: 'Kode Gambar',
      dataIndex: 'kodeGambar',
      key: 'kodeGambar',
    },
    {
      title: 'Pakaian',
      dataIndex: 'pakaian',
      key: 'pakaian',
      render: (_, record) => (
        record.merek + " - " + record.jenisPakaian + " - " + record.namaPakaian + "-" + record.jenisBahan + " - " + record.sizeGroup
      ),
    },
    {
      title: 'Total Jumlah Lusin',
      dataIndex: 'totalLusin',
      key: 'totalLusin',
    },
    {
      title: 'Total Jumlah Piece',
      dataIndex: 'totalPiece',
      key: 'totalPiece',
    },
    {
      title: 'Jumlah Harga',
      dataIndex: 'totalPrice',
      key: 'totalPrice',
      render: (_, record) => (
        new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(record.totalPrice).trim().split(",")[0]
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button shape="circle" icon={selectedData.status !== "Done" ? <EditOutlined /> : <EyeOutlined />} onClick={() => {onClickEditGambar(record)}}/>
          {selectedData.status !== "Done" && <Button shape="circle" icon={<DeleteOutlined />} onClick={() => {showDeleteConfirmGambar(record);}}/>}
        </Space>
      ),
    },
  ];

  const tableColumnsGambarDetail = [
    {
      title: 'Ukuran',
      dataIndex: 'ukuran',
      key: 'ukuran',
      render: (_, record) => (
        <>
          {record.ukuran + " "}
          {selectedData.status !== "Done" && record.exceedLimit && <ExclamationCircleFilled />}
          {selectedData.status !== "Done" && record.exceedLimit && " (melebihi ketersediaan stok)"}
        </>
      ),
    },
    {
      title: 'Jumlah Lusin',
      dataIndex: 'totalLusin',
      key: 'totalLusin',
    },
    {
      title: 'Jumlah Piece',
      dataIndex: 'totalPiece',
      key: 'totalPiece',
    },
    {
      title: 'Harga Jual Toko',
      dataIndex: 'hargaJualToko',
      key: 'hargaJualToko',
      render: (_, record) => (
        new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(record.hargaJualToko).trim().split(",")[0]
      ),
    },
  ];

  const print = (record) => {
    let encodedId = btoa(record.id);
    window.open(BASE_URL + "/v1/generate/report/sales-invoice/Sales Invoice " + record.noInvoice + "?id=" + encodedId);
  };

  const handleChangeOption = (value) => {
    setFilterOptionSelected(value);
    setFilterSearchDateValue(null);
    setFilterSearchValue('');
  };

  const handleChangeValue = (e) => {
    setFilterSearchValue(e.target.value);
  };

  const handleChangeDateValue = (date, dateString) => {
    setFilterSearchDateValue(date);
    setFilterSearchValue(dateString);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    var sortField = sorter.field;
    var sortOrder = sorter.order;
    setTableParams({
      pagination,
      filters,
      sortOrder: sortOrder,
      sortField: sortField,
    });
    
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  const handleTableChangeGambar = (pagination, filters, sorter) => {
    var sortField = sorter.field;
    var sortOrder = sorter.order;
    setTableParamsGambar({
      pagination,
      filters,
      sortOrder: sortOrder,
      sortField: sortField,
    });
    
    if (pagination.pageSize !== tableParamsGambar.pagination?.pageSize) {
      setDataGambar([]);
    }
  };

  const fetchData = async () => {
    setLoading(true);

    var sortField = tableParams.sortField === undefined ? "updatedAt" : tableParams.sortField;
    var sortOrder = tableParams.sortOrder === "ascend" ? "asc" : "desc";

    if (tableParams.sortOrder === undefined || tableParams.sortOrder === null) {
      sortOrder = "desc";
      sortField = "updatedAt";
    }

    const response = await dispatch(salesInvoiceApi.getPage({
      "page": tableParams.pagination.current,
      "size": tableParams.pagination.pageSize,
      "sort": [
        sortField + " " + sortOrder,
      ],
      "search": [
        {
          field: filterOptionSelected,
          value: filterSearchValue
        }
      ]
    }));
    if (response.status === 200) {
      setData(response.data.contents);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: response.data.totalData,
        }
      });
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
    setLoading(false);
  };

  const fetchDataGambar = async () => {
    if (selectedData.id === null) return;
    var sortField = tableParamsGambar.sortField === undefined ? "id" : tableParamsGambar.sortField;
    var sortOrder = tableParamsGambar.sortOrder === "ascend" ? "asc" : "desc";

    if (tableParamsGambar.sortOrder === undefined || tableParamsGambar.sortOrder === null) {
      sortOrder = "desc";
      sortField = "id";
    }

    const response = await dispatch(salesInvoiceApi.getPageGambar(selectedData.id, {
      "page": tableParamsGambar.pagination.current,
      "size": tableParamsGambar.pagination.pageSize,
      "sort": [
        sortField + " " + sortOrder,
      ],
      "search": []
    }));
    if (response.status === 200) {
      setDataGambar(response.data.contents);
      setTableParamsGambar({
        ...tableParamsGambar,
        pagination: {
          ...tableParamsGambar.pagination,
          total: response.data.totalData,
        }
      });
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
    setLoading(false);
  };

  const onClickEdit = (record) => {
    setSelectedData(record);
    form.setFieldsValue({
      ...record,
      tanggalInvoice: record.tanggalInvoice !== null ? dayjs(record.tanggalInvoice) : null,
      tanggalKirim: record.tanggalKirim !== null ? dayjs(record.tanggalKirim): null,
      knownByDate: record.knownByDate !== null ? dayjs(record.knownByDate): null,
      acceptedByDate: record.acceptedByDate !== null ? dayjs(record.acceptedByDate): null,
      discountAmount: record.discountType !== null && record.discountType === "Rp" ? new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(record.discountAmount).replace('Rp', '').trim().split(",")[0] : record.discountAmount,
      logoId: record.logoId !== null ? record.logoId + "" : null,
      tokoId: record.tokoId !== null ? record.tokoId + "" : null,
      sourceTokoId: record.sourceTokoId !== null ? record.sourceTokoId + "" : null,
    });
    setIsOpenModal(true);
  }

  const showDeleteConfirm = (record) => {
    confirm({
      title: 'Are you sure delete this item?',
      icon: <ExclamationCircleFilled />,
      content: 'You can\'t undo this operation when finished!',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      centered: true,
      onOk() {
        deleteData(record);
      },
    });
  };

  const deleteData = async (record) => {
    setLoading(true);
    const response = await dispatch(salesInvoiceApi.deleteItem(record.id));
    if (response.status === 200) {
      resetData();
      if (tableParams.pagination.current > 1) {
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            current: 1,
          }
        });
      } else {
        fetchData();
      }
      openNotification('Success', 'Success to delete item!', 'success');
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    }
    setLoading(false);
  };

  const onClickEditGambar = (record) => {
    setIsOpenModalGambarDetail(true);
    setSelectedDataGambar(record);
    formGambarDetail.setFieldsValue(record);
  }

  const showDeleteConfirmGambar = (record) => {
    confirm({
      title: 'Are you sure delete this item?',
      icon: <ExclamationCircleFilled />,
      content: 'You can\'t undo this operation when finished!',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      centered: true,
      onOk() {
        deleteDataGambar(record);
      },
    });
  };

  const deleteDataGambar = async (record) => {
    const response = await dispatch(salesInvoiceApi.deleteGambar(selectedData.id, record.id));
    if (response.status === 200) {
      if (tableParams.pagination.current > 1) {
        setTableParamsGambar({
          ...tableParamsGambar,
          pagination: {
            ...tableParamsGambar.pagination,
            current: 1,
          }
        });
      } else {
        fetchDataGambar();
      }
      recalculatePrice();
      openNotification('Success', 'Success to delete gambar!', 'success');
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    }
  };

  const resetSearchGambar = () => {
    setGambarSearchParam({
      merek: '',
      jenisPakaian: '',
      namaPakaian: '',
      sizeGroup: '',
      kodeGambar: ''
    });
    formGambar.resetFields();
  }
  
  const resetData = () => {
    form.resetFields();
    form.setFieldsValue({
      id: null,
      sourceTokoId: null,
      tipe: null,
      noInvoice: null,
      logoId: null,
      logoUrl: null,
      tanggalInvoice: null,
      tokoId: null,
      namaToko: null,
      tanggalKirim: null,
      alamat: null,
      discountType: null,
      discountAmount: null,
      totalLusin: null,
      totalPieces: null,
      totalPrice: null,
      description: null,
      paymentType: null,
      acceptedBy: null,
      acceptedByDate: null,
      knownBy: null,
      knownByDate: null,
      status: null,
    });
    setSelectedData({
      id: null,
      sourceTokoId: null,
      tipe: null,
      noInvoice: null,
      logoId: null,
      logoUrl: null,
      tanggalInvoice: null,
      tokoId: null,
      namaToko: null,
      tanggalKirim: null,
      alamat: null,
      discountType: null,
      discountAmount: null,
      totalLusin: null,
      totalPieces: null,
      totalPrice: null,
      description: null,
      paymentType: null,
      acceptedBy: null,
      acceptedByDate: null,
      knownBy: null,
      knownByDate: null,
      status: null,
    });
  }

  const fetchAddressByCustomerId = async (id) => {
    const response = await dispatch(customerApi.getDetailAddressByCustomerId(id));
    if (response.status === 200) {
      setSelectedData({
        ...selectedData,
        tokoId: id,
        alamat: response.data,
      });
      form.setFieldValue('alamat', response.data);
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
  }

  const fetchMerek = async () => {
    const response = await dispatch(merekApi.getMerekListWithId());
    if (response.status === 200) {
      setMerekOpt(response.data);
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
  }

  const fetchToko = async () => {
    const response = await dispatch(customerApi.getTokoListWithId());
    if (response.status === 200) {
      setTokoOpt(response.data);
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
  };

  const fetchTokoSource = async () => {
    const response = await dispatch(stokTokoApi.getDropdown('toko'));
    if (response.status === 200) {
      setSourceTokoOpt(response.data);
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
  };

  const fetchDropdown = async (tipe) => {
    if (selectedData.id === null) return;
    const response = await dispatch(salesInvoiceApi.getDropdownList(selectedData.id, tipe, {
      ...gambarSearchParam,
      sizeGroup: tipe === 'size-group' ? '' : gambarSearchParam.sizeGroup
    }));
    if (response.status === 200) {
      if (tipe === 'jenis-bahan') {
        setJenisBahanStokOpt(response.data);
      } else if (tipe === 'jenis-pakaian') {
        setJenisPakaianStokOpt(response.data);
      } else if (tipe === 'merek') {
        setMerekStokOpt(response.data);
      } else if (tipe === 'nama-pakaian') {
        setNamaPakaianStokOpt(response.data);
      } else if (tipe === 'size-group') {
        setSizeGroupMerekStokOpt(response.data);
      }
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
  };

  const fetchGambar = async () => {
    if (selectedData.id === null) return;
    const response = await dispatch(salesInvoiceApi.getGambarStok(selectedData.id, gambarSearchParam));
    if (response.status === 200) {
      response.data.forEach(obj => {
        obj.urlGambar = BASE_URL + obj.urlGambar;
      });
      setGambarOpt(response.data);
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
  };
  
  const submit = async (values) => {
    if (selectedData.id === null) {
      const response = await dispatch(salesInvoiceApi.init(values.tipe, values.sourceTokoId));

      if (response.status === 200) {
        response.data = {
          ...response.data,
          sourceTokoId: response.data.sourceTokoId !== null ? response.data.sourceTokoId + "" : null,
        }
        setSelectedData(response.data);
        form.setFieldsValue(response.data);
        if (tableParams.pagination.current > 1) {
          setTableParams({
            ...tableParams,
            pagination: {
              ...tableParams.pagination,
              current: 1,
            }
          });
        } else {
          fetchData();
        }
      } else if (response.status === 403) {
        openNotification('Forbidden', response.errors[0].message, 'error');
      } else if (response.status === 400) {
        openNotification('Error', response.errors[0].message, 'error');
      } else {
        openNotification('Error', 'Something went wrong. Please try again', 'error');
      }
    } else {
      setIsOpenModal(false);
      const response = await dispatch(salesInvoiceApi.post(selectedData));
      if (response.status === 200) {
        fetchDataGambar();
        if (tableParams.pagination.current > 1) {
          setTableParams({
            ...tableParams,
            pagination: {
              ...tableParams.pagination,
              current: 1,
            }
          });
        } else {
          fetchData();
        }
        openNotification('Success', 'Success to submit data!', 'success');
        resetData();
      } else if (response.status === 403) {
        openNotification('Forbidden', response.errors[0].message, 'error');
      } else if (response.status === 400) {
        openNotification('Error', response.errors[0].message, 'error');
        setIsOpenModal(true);
        if (response.errors[0].message === "Terdapat pengisian data yang melebihi batas ketersediaan stok") {
          fetchDataGambar();
        }
      } else {
        openNotification('Error', 'Something went wrong. Please try again', 'error');
        setIsOpenModal(true);
      }
    }
  };

  const onCancel = () => {
    resetData();
    fetchData();
    setIsOpenModal(false);
  };

  const recalculatePrice = async () => {
    if (selectedData.id === null) return;
    const response = await dispatch(salesInvoiceApi.recalculatePrice({
      id: selectedData.id,
      discountType: selectedData.discountType,
      discountAmount: selectedData.discountAmount,
    }));
    if (response.status === 200) {
      setSelectedData({
        ...selectedData,
        totalPrice: response.data,
      });

      form.setFieldValue("totalPriceItem", new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(response.data).replace('Rp', '').trim().split(",")[0])
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }

  }

  const addGambar = async () => {
    const response = await dispatch(salesInvoiceApi.addGambarStok(selectedData.id, selectedGambarOpt));
    setIsOpenModalPilihGambar(false);
    if (response.status === 200) {
      if (tableParamsGambar.pagination.current > 1) {
        setTableParamsGambar({
          ...tableParamsGambar,
          pagination: {
            ...tableParamsGambar.pagination,
            current: 1,
          }
        });
      } else {
        fetchDataGambar();
      }
      resetSearchGambar();
      setSelectedGambarOpt([]);
      openNotification('Success', 'Success to add gambar!', 'success');
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
      setIsOpenModalPilihGambar(true);
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
      setIsOpenModalPilihGambar(true);
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
      setIsOpenModalPilihGambar(true);
    }
  }

  const expandedRowRender = (record) => {
    return (
      <Table columns={tableColumnsGambarDetail} dataSource={record.sizes.filter(item => (item.totalLusin !== null || item.totalPiece !== null) && (item.totalLusin > 0 || item.totalPiece > 0))} pagination={false} />
    );
  }

  const submitGambarDetail = async () => {
    if (selectedData.id === null) return;
    setForceReloadSize(true);
    setTimeout(() => {
      setIsOpenModalGambarDetail(false);
    }, 50);
    const response = await dispatch(salesInvoiceApi.assignSalesInvoiceSize(selectedData.id, selectedDataGambar.id, selectedDataGambar.sizes));
    if (response.status === 200) {
      setForceReloadSize(false);
      recalculatePrice();
      setSelectedDataGambar({
        id: null,
        salesInvoiceId: null,
        stokGambarId: null,
        kodeGambar: null,
        urlGambar: null,
        merek: null,
        jenisPakaian: null,
        namaPakaian: null,
        jenisBahan: null,
        sizeGroup: null,
        totalLusin: null,
        totalPiece: null,
        totalPrice: null,
        sizes: [{
          id: null,
          salesInvoiceId: null,
          salesInvoiceImageId: null,
          stokGambarDetailId: null,
          ukuran: null,
          totalLusin: null,
          totalPiece: null,
          hargaJualToko: null,
          availableStok: null,
          exceedLimit: null
        }]
      });
      formGambarDetail.resetFields();
      formGambarDetail.setFieldsValue({
        id: null,
        salesInvoiceId: null,
        stokGambarId: null,
        kodeGambar: null,
        urlGambar: null,
        merek: null,
        jenisPakaian: null,
        namaPakaian: null,
        jenisBahan: null,
        sizeGroup: null,
        totalLusin: null,
        totalPiece: null,
        totalPrice: null,
        sizes: [{
          id: null,
          salesInvoiceId: null,
          salesInvoiceImageId: null,
          stokGambarDetailId: null,
          ukuran: null,
          totalLusin: null,
          totalPiece: null,
          hargaJualToko: null,
          availableStok: null,
          exceedLimit: null
        }]
      });

      if (tableParamsGambar.pagination.current > 1) {
        setTableParamsGambar({
          ...tableParamsGambar,
          pagination: {
            ...tableParamsGambar.pagination,
            current: 1,
          }
        });
      } else {
        fetchDataGambar();
      }
      openNotification('Success', 'Success to assign size!', 'success');
    } else if (response.status === 403) {
      setForceReloadSize(false);
      openNotification('Forbidden', response.errors[0].message, 'error');
      setIsOpenModalGambarDetail(true);
    } else if (response.status === 400) {
      setForceReloadSize(false);
      openNotification('Error', response.errors[0].message, 'error');
      setIsOpenModalGambarDetail(true);
    } else {
      setForceReloadSize(false);
      openNotification('Error', 'Something went wrong. Please try again', 'error');
      setIsOpenModalGambarDetail(true);
    }
  }

  const reassignSize = (id, field, value) => {
    let selectedDataNew = selectedDataGambar;
    selectedDataNew.sizes.map(obj => {
      if (obj.id === id) {
        if (field === "lusin") {
          obj.totalLusin = value;
        } else if (field === "piece") {
          obj.totalPiece = value;
        }
      }
    });

    setSelectedDataGambar(selectedDataNew);
  }

  useEffect(() => {
    fetchData();
  }, [
    filterOptionSelected,
    filterSearchValue,
    tableParams.pagination?.current,
    tableParams.pagination?.pageSize,
    tableParams?.sortOrder,
    tableParams?.sortField,
  ]);

  useEffect(() => {
    fetchDataGambar();
  }, [
    selectedData.id,
    tableParamsGambar.pagination?.current,
    tableParamsGambar.pagination?.pageSize,
    tableParamsGambar?.sortOrder,
    tableParamsGambar?.sortField,
  ]);

  useEffect(() => {
    fetchMerek();
    fetchToko();
    fetchTokoSource();
  }, []);

  useEffect(() => {
    recalculatePrice();
  }, [
    selectedData.discountAmount, 
    selectedData.discountType
  ]);

  useEffect(() => {
    fetchDropdown('jenis-bahan');
    fetchDropdown('jenis-pakaian');
    fetchDropdown('merek');
    fetchDropdown('nama-pakaian');
    fetchDropdown('size-group');
  }, [
    selectedData.id,
    gambarSearchParam.jenisBahan,
    gambarSearchParam.jenisPakaian,
    gambarSearchParam.merek,
    gambarSearchParam.namaPakaian,
    gambarSearchParam.sizeGroup,
  ]);

  useEffect(() => {
    fetchGambar();
  }, [
    selectedData.id,
    gambarSearchParam.jenisBahan,
    gambarSearchParam.jenisPakaian,
    gambarSearchParam.merek,
    gambarSearchParam.namaPakaian,
    gambarSearchParam.sizeGroup,
    gambarSearchParam.kodeGambar,
  ]);

  return (
    <Context.Provider value={true}>
      {contextHolder}
      
      <Flex gap="middle" vertical={false}>
        <Space>
          <Select
            size="large"
            value={filterOptionSelected}
            onChange={handleChangeOption}
            style={{ width: 250 }}
            options={filterOptions}
          />
          {
            filterOptionSelected !== "tanggalInvoice" && filterOptionSelected !== "tanggalKirim" && 
            <Input 
              size="large"
              style={{ width: 300 }}
              onChange={handleChangeValue}
              value={filterSearchValue} />
          }
          {
            (filterOptionSelected === "tanggalInvoice" || filterOptionSelected === "tanggalKirim") &&
            <DatePicker 
              size='large'
              style={{width: 300}}
              onChange={handleChangeDateValue}
              value={filterSearchDateValue}
              placeholder=''
            />
          }
        </Space>
        <Space align='end'
          style={{ flexBasis: '100%', flexDirection: 'column-reverse' }}
          >
          <Button 
            size="large" 
            type="primary"
            icon={<PlusOutlined />}
            style={{ width: 200 }}
            iconPosition='start'
            onClick={() => {
              setIsOpenModal(true);
            }}
          >
            Add Sales Invoice
          </Button>
        </Space>
      </Flex>

      <Flex gap="middle" vertical style={{marginTop: '24px'}}>
        <Table
          columns={tableColumns}
          rowKey={(record) => record.id}
          dataSource={data}
          pagination={tableParams.pagination}
          loading={{
            spinning: loading,
            indicator: <Spinner className="spinner"/>
          }}
          onChange={handleTableChange}
        />
      </Flex>
      <Modal
        title={selectedData.id === null ? "Add Sales Invoice" : (selectedData.status !== "Done" ? "Edit Sales Invoice" : "View Sales Invoice")}
        open={isOpenModal}
        width={900}
        closable={false}
        footer={[]}
      >
        <Form
          form={form}
          name="form"
          className="form"
          layout="vertical"
          autoComplete="off"
          autoFocus={true}
          onFinish={submit}
        >
          <Form.Item
            layout="vertical"
            label="Jenis Invoice"
            name="tipe"
            rules={[
              {
                required: true,
                message: 'Pilih jenis invoice',
              },
            ]}
          >
            <Select 
              disabled={selectedData.id !== null}
              showSearch
              placeholder="Pilih jenis invoice"
              options={salesInvoiceType} 
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              optionLabelProp="label"
              onChange={(data) => {
                setSelectedData({
                  ...selectedData,
                  tipe: data,
                });
              }}
              size="large" />
          </Form.Item>

          {
            selectedData.tipe === 3 && 
            <Form.Item
              layout="vertical"
              label="Sumber Stok Toko"
              name="sourceTokoId"
              rules={[
                {
                  required: true,
                  message: 'Pilih Sumber Stok Toko',
                },
              ]}
            >
              <Select 
                disabled={selectedData.id !== null}
                showSearch
                placeholder="Pilih sumber stok toko"
                options={sourceTokoOpt} 
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                optionLabelProp="label"
                onChange={(data) => {
                  setSelectedData({
                    ...selectedData,
                    sourceTokoId: data,
                  });
                }}
                size="large" />
            </Form.Item>
          }

          {
            selectedData.id !== null && 
            <>
            <Form.Item
              layout="vertical"
              label="Nomor Invoice"
              name="noInvoice"
            >
              <Input size='large' style={{width: '100%'}} disabled
                placeholder="Auto generated after click continue" />
            </Form.Item>

            <Form.Item
              layout="vertical"
              label="Logo"
              name="logoId"
              rules={[
                {
                  required: true,
                  message: 'Pilih logo',
                },
              ]}
            >
              <Select 
                disabled={selectedData.status === "Done"}
                showSearch
                placeholder="Pilih logo"
                options={merekOpt} 
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                optionLabelProp="label"
                onChange={(data) => {
                  setSelectedData({
                    ...selectedData,
                    logoId: parseInt(data),
                  });
                }}
                size="large" />
            </Form.Item>

            <Space>
              <Form.Item
                layout="vertical"
                label="Tanggal Invoice"
                name="tanggalInvoice"
                style={{width: '278px'}}
                rules={[
                  {
                    required: true,
                    message: 'Pilih tanggal invoice',
                  },
                ]}
              >
                <DatePicker 
                  disabled={selectedData.status === "Done"}
                  size='large'
                  style={{width: '100%'}}
                  onChange={(date, dateString) => {
                    setSelectedData({
                      ...selectedData,
                      tanggalInvoice: dateString
                    });
                  }}
                />
              </Form.Item>
              <Form.Item
                layout="vertical"
                label="Nama Toko"
                name="tokoId"
                style={{width: '278px'}}
                rules={[
                  {
                    required: true,
                    message: 'Pilih nama toko',
                  },
                ]}
              >
                <Select 
                  disabled={selectedData.status === "Done"}
                  showSearch
                  placeholder="Pilih nama toko"
                  options={tokoOpt} 
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  optionLabelProp="label"
                  onChange={(data) => {
                    fetchAddressByCustomerId(parseInt(data));
                  }}
                  size="large" />
              </Form.Item>
              <Form.Item
                layout="vertical"
                label="Tanggal Kirim"
                name="tanggalKirim"
                style={{width: '278px'}}
                rules={[
                  {
                    required: true,
                    message: 'Pilih tanggal kirim',
                  },
                ]}
              >
                <DatePicker 
                  disabled={selectedData.status === "Done"}
                  size='large'
                  style={{width: '100%'}}
                  onChange={(date, dateString) => {
                    setSelectedData({
                      ...selectedData,
                      tanggalKirim: dateString
                    });
                  }}
                />
              </Form.Item>
            </Space>

            <Form.Item
              layout="vertical"
              label="Alamat"
              name="alamat"
            >
              <Input size='large' style={{width: '100%'}} disabled
                placeholder="Alamat" />
            </Form.Item>

            <Divider />

            <Space align='end' style={{ justifyContent: 'end', width: '100%' }}>
              {
                selectedData.status !== "Done" &&
                <Button 
                  size="large" 
                  type="primary"
                  icon={<PlusOutlined />}
                  style={{ width: 180, marginBottom: '8px' }}
                  iconPosition='start'
                  onClick={() => {setIsOpenModalPilihGambar(true)}}
                >
                  Add Gambar
                </Button>
              }
            </Space>


            <Flex gap="middle" vertical style={{marginTop: '24px', marginBottom: '16px'}}>
              <Table
                columns={tableColumnsGambar}
                rowKey={(record) => record.id}
                dataSource={dataGambar}
                pagination={tableParamsGambar.pagination}
                loading={{
                  spinning: loading,
                  indicator: <Spinner className="spinner"/>
                }}
                onChange={handleTableChangeGambar}
                size="small"
                expandable={{
                  expandedRowRender,
                }}
              />
            </Flex>
            <Space>
              <Form.Item
                layout="vertical"
                label="Jenis Diskon"
                name="discountType"
                style={{width: '278px'}}
              >
                <Select 
                  showSearch
                  allowClear
                  disabled={selectedData.status === "Done"}
                  placeholder="Pilih jenis diskon"
                  options={discountTypeOpt} 
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  optionLabelProp="label"
                  onChange={(data) => {
                    setSelectedData({
                      ...selectedData,
                      discountType: data === undefined ? null : data,
                      discountAmount: null,
                    });
                    form.setFieldValue("discountAmount", null);
                  }}
                  size="large" />
              </Form.Item>
              <Form.Item
                layout="vertical"
                label="&nbsp;"
                name="discountAmount"
                style={{width: '278px'}}
                rules={[
                  {
                    required: selectedData.discountType !== null && selectedData.discountType !== "" && selectedData.discountType !== undefined,
                    message: 'Please input nominal diskon',
                  },
                ]}
              >
                {
                  selectedData.discountType !== "Rp" &&
                  <InputNumber size='large' min={0} style={{width: '100%'}} 
                    disabled={selectedData.status === "Done"}
                    onBlur={(e) => {
                      setSelectedData({
                        ...selectedData,
                        discountAmount: e.target.value
                      });
                    }}
                    placeholder="Jumlah diskon"/>
                }
                {
                  selectedData.discountType === "Rp" &&
                  <Input size='large' style={{width: '100%'}} addonBefore="Rp"
                    disabled={selectedData.status === "Done"}
                    onBlur={(e) => {
                      let value = e.target.value.replace(/\D/g, '');
                      let formattedNumber = new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(value).replace('Rp', '').trim().split(",")[0];
                      form.setFieldValue('discountAmount', formattedNumber);

                      setSelectedData({
                        ...selectedData,
                        discountAmount: parseInt(formattedNumber.replaceAll('.', ''))
                      });
                    }}
                    placeholder="Jumlah diskon"/>
                }
              </Form.Item>

              <Form.Item
                layout="vertical"
                label="Sub Total"
                name="totalPriceItem"
                style={{width: '278px'}}
                initialValue={new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(selectedData.totalPrice).replace('Rp', '').trim().split(",")[0]}
              >
                <Input size='large' addonBefore="Rp" min={0} style={{width: '100%'}} disabled
                  placeholder="Sub total" />
              </Form.Item>
            </Space>

            <Form.Item
              layout="vertical"
              label="Deskripsi"
              name="description"
            >
              <Input.TextArea 
                disabled={selectedData.status === "Done"}
                autoSize={{ minRows: 3, maxRows: 5 }} maxLength={250} onChange={(e) => {
                  setSelectedData({
                    ...selectedData,
                    description: e.target.value,
                  });
                }}/>
            </Form.Item>
            <Form.Item
              layout="vertical"
              label="Jenis Pembayaran"
              name="paymentType"
              rules={[
                {
                  required: true,
                  message: 'Pilih jenis pembayaran',
                },
              ]}
            >
              <Select 
                disabled={selectedData.status === "Done"}
                showSearch
                placeholder="Pilih jenis pembayaran"
                options={paymentType} 
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                optionLabelProp="label"
                onChange={(data) => {
                  setSelectedData({
                    ...selectedData,
                    paymentType: data,
                  })
                }}
                size="large" />
            </Form.Item>

            <h3 style={{textAlign: 'center'}}>Paraf & Keterangan</h3>
            <Divider />
            <Space>
              <Form.Item
                layout="vertical"
                label="Diterima Oleh"
                name="acceptedBy"
                style={{width: '566px'}}
                rules={[
                  {
                    required: true,
                    message: 'Input diterima oleh',
                  },
                ]}
              >
                <Input size='large' style={{width: '100%'}}
                  disabled={selectedData.status === "Done"}
                  onChange={(e) => {
                    setSelectedData({
                      ...selectedData,
                      acceptedBy: e.target.value,
                    });
                  }}
                  placeholder="Diterima oleh" />
              </Form.Item>
              <Form.Item
                layout="vertical"
                label="Tanggal"
                name="acceptedByDate"
                style={{width: '278px'}}
                rules={[
                  {
                    required: true,
                    message: 'Pilih tanggal',
                  },
                ]}
              >
                <DatePicker 
                  disabled={selectedData.status === "Done"}
                  size='large'
                  style={{width: '100%'}}
                  onChange={(date, dateString) => {
                    setSelectedData({
                      ...selectedData,
                      acceptedByDate: dateString
                    });
                  }}
                />
              </Form.Item>

            </Space>
            <Space>
              <Form.Item
                layout="vertical"
                label="Hormat Kami"
                name="knownBy"
                style={{width: '566px'}}
                rules={[
                  {
                    required: true,
                    message: 'Input hormat kami',
                  },
                ]}
              >
                <Input size='large' style={{width: '100%'}}
                  disabled={selectedData.status === "Done"}
                  onChange={(e) => {
                    setSelectedData({
                      ...selectedData,
                      knownBy: e.target.value,
                    });
                  }}
                  placeholder="Hormat kami" />
              </Form.Item>
              <Form.Item
                layout="vertical"
                label="Tanggal"
                name="knownByDate"
                style={{width: '278px'}}
                rules={[
                  {
                    required: true,
                    message: 'Pilih tanggal',
                  },
                ]}
              >
                <DatePicker 
                  disabled={selectedData.status === "Done"}
                  size='large'
                  style={{width: '100%'}}
                  onChange={(date, dateString) => {
                    setSelectedData({
                      ...selectedData,
                      knownByDate: dateString
                    });
                  }}
                />
              </Form.Item>

            </Space>
            </>
          }
          
          <Space
            align='end'
            style={{ flexBasis: '100%', flexDirection: 'column-reverse', width: '100%', marginTop: '28px', }}
          >
            <Form.Item>
              <Button htmlType="button" size="large" style={{marginRight: '8px'}} onClick={onCancel}> 
                {selectedData.status !== "Done" ? "Cancel" : "Close"}
              </Button>
              {
                selectedData.status !== "Done" &&
                <Button type="primary" htmlType="submit" size="large">
                  {selectedData.id === null ? "Save to Continue" : "Submit"}
                </Button>
              }
            </Form.Item>
          </Space>
        </Form>
      </Modal>

      <Modal
        title="Add Gambar"
        open={isOpenModalPilihGambar}
        width={785}
        closable={false}
        footer={[]}
      >
        <Form
          form={formGambar}
          name="form-gambar"
          className="form"
          layout="vertical"
          autoComplete="off"
          autoFocus={true}
        >
          <h3>Silahkan isi isian dibawah ini untuk mencari / filter gambar</h3>
          <Form.Item
            layout="vertical"
            label="Nama Merek"
            name="merek"
          >
            <Select 
              showSearch
              allowClear
              placeholder="Pilih nama merek"
              options={merekStokOpt} 
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              optionLabelProp="label"
              onChange={(data) => {
                setGambarSearchParam({
                  ...gambarSearchParam,
                  merek: data,
                })
              }}
              size="large" />
          </Form.Item>
          <Space>
            <Form.Item
              layout="vertical"
              label="Jenis Pakaian"
              name="jenisPakaian"
              style={{width: '278px'}}
            >
              <Select 
                showSearch
                allowClear
                placeholder="Pilih jenis pakaian"
                options={jenisPakaianStokOpt} 
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                optionLabelProp="label"
                onChange={(data) => {
                  setGambarSearchParam({
                    ...gambarSearchParam,
                    jenisPakaian: data,
                  })
                }}
                size="large" />
            </Form.Item>
            <Form.Item
              layout="vertical"
              label="Nama Pakaian"
              name="namaPakaian"
              style={{width: '450px'}}
            >
              <Select 
                showSearch
                allowClear
                placeholder="Pilih nama pakaian"
                options={namaPakaianStokOpt} 
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                optionLabelProp="label"
                onChange={(data) => {
                  setGambarSearchParam({
                    ...gambarSearchParam,
                    namaPakaian: data,
                  })
                }}
                size="large" />
            </Form.Item>
          </Space>
          <Space>
            <Form.Item
              layout="vertical"
              label="Jenis Bahan"
              name="jenisBahan"
              style={{width: '364px'}}
            >
              <Select 
                showSearch
                allowClear
                placeholder="Pilih jenis bahan"
                options={jenisBahanStokOpt} 
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                optionLabelProp="label"
                onChange={(data) => {
                  setGambarSearchParam({
                    ...gambarSearchParam,
                    jenisBahan: data,
                  })
                }}
                size="large" />
            </Form.Item>
            <Form.Item
              layout="vertical"
              label="Ukuran"
              name="sizeGroup"
              style={{width: '364px'}}
            >
              <Select 
                showSearch
                allowClear
                placeholder="Pilih ukuran"
                options={sizeGroupStokOpt} 
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                optionLabelProp="label"
                onChange={(data) => {
                  setGambarSearchParam({
                    ...gambarSearchParam,
                    sizeGroup: data,
                  })
                }}
                size="large" />
            </Form.Item>
          </Space>
          <Form.Item
            layout="vertical"
            label="Kode Gambar / Nama Gambar"
            name="kodeGambar"
          >
            <Input 
              placeholder="Cari kode gambar atau nama gambar"
              size='large'
              onChange={(e) => {
                setGambarSearchParam({
                  ...gambarSearchParam,
                  kodeGambar: e.target.value,
                })
              }}
              style={{marginBottom: '16px'}} />
          </Form.Item>
        </Form>
        <Space style={{flexWrap:'wrap', maxHeight: '450px', overflowY: 'scroll'}}>
          {
            gambarOpt.map(obj => (
              <Card
                key={obj.id}
                hoverable
                style={{
                  width: 230
                }}
                cover={
                  <Image
                    height={150}
                    style={{objectFit: 'contain'}}
                    src={obj.urlGambar}
                  />
                }
              >
                <Meta title={obj.kodeGambar} description={obj.namaGambar} />
                <p>{obj.merek} </p>
                <label>{obj.jenisPakaian} - </label>
                <label>{obj.namaPakaian} </label>
                <p>{obj.jenisBahan} </p>
                <p>{obj.ukuran}</p>
                <Checkbox 
                  className='card-image-checkbox' 
                  checked={selectedGambarOpt.includes(obj.id)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      let temp = [obj.id];
                      let combinedList = [...new Set([...selectedGambarOpt, ...temp])];
                      setSelectedGambarOpt(combinedList);
                    } else {
                      let updatedList = selectedGambarOpt.filter(gambar => gambar !== (obj.id));
                      setSelectedGambarOpt(updatedList);
                    }
                  }} />
              </Card>
            ))
          }
        </Space>
        <Space
          align='end'
          style={{ flexBasis: '100%', flexDirection: 'column-reverse', width: '100%', marginTop: '28px', }}
        >
          <Form.Item>
            <Button htmlType="button" size="large" style={{marginRight: '8px'}} 
            onClick={() => {
              setIsOpenModalPilihGambar(false);
              resetSearchGambar();
              setSelectedGambarOpt([]);
            }}> 
              Close
            </Button>
            <Button type="primary" htmlType="button" size="large" style={{marginRight: '8px'}} disabled={selectedGambarOpt.length < 1}
              onClick={addGambar}
            > 
              Add
            </Button>
          </Form.Item>
        </Space>
      </Modal>
      <Modal
        title="Gambar Detail"
        open={isOpenModalGambarDetail}
        width={785}
        closable={false}
        footer={[]}
      >
        <Form
          form={formGambarDetail}
          name="form-gambar-detail"
          className="form"
          layout="vertical"
          autoComplete="off"
          autoFocus={true}
        >
          <Form.Item
            layout="vertical"
            label="Kode Gambar"
            name="kodeGambar"
          >
            <Input size='large' style={{width: '100%'}} disabled
              placeholder="Kode gambar" />
          </Form.Item>

          <Space style={{marginBottom: '16px', border:'1px solid #b8ded8', borderRadius: '8px'}}>
            <Image
              alt={selectedDataGambar.namaGambar === null ? "no-image": selectedDataGambar.namaGambar}
              width={284}
              height={284}
              style={{objectFit: 'contain', padding: '12px'}}
              src={selectedDataGambar.urlGambar === null ? noImage : BASE_URL + selectedDataGambar.urlGambar}
            />
          </Space>
        </Form>
        <Space style={{display: 'block'}}>
        {
          forceReloadSize === false && selectedDataGambar.sizes.map(obj => (
            <Space key={obj.id}>
              <Form.Item
                layout="vertical"
                label=""
                name={"size" +obj.id}
                style={{width: '300px'}}
              >
                <Input size="large" 
                  defaultValue={selectedData.status === "Done" ? obj.ukuran : obj.ukuran + " (Stok tersedia " + obj.availableStok + " pieces)"}
                  placeholder='Ukuran' disabled
                 />
              </Form.Item>
              <Form.Item
                layout="vertical"
                label=""
                name={"jumlahLusin"+obj.id}
                style={{width: '211px'}}
              >
                <InputNumber 
                  style={{width: '100%'}} 
                  size="large" 
                  defaultValue={obj.totalLusin === 0 ? null : obj.totalLusin}
                  placeholder={selectedData.status === "Done" ? 'Jumlah lusin' : 'Jumlah lusin, max: ' + Math.floor(obj.availableStok / 12)}
                  disabled={selectedData.status === "Done" || Math.floor(obj.availableStok / 12) === 0} 
                  max={Math.floor(obj.availableStok / 12)}
                  min={1}
                  onChange={(value) => {
                    reassignSize(obj.id, 'lusin', value);
                  }}
                />
              </Form.Item>
              <Form.Item
                layout="vertical"
                label=""
                name={"jumlahPiece"+obj.id}
                style={{width: '211px'}}
              >
                <InputNumber 
                  style={{width: '100%'}} 
                  size="large" 
                  defaultValue={obj.totalPiece === 0 ? null : obj.totalPiece}
                  placeholder={selectedData.status === "Done" ? 'Jumlah piece' : 'Jumlah piece, max: ' + obj.availableStok}
                  disabled={selectedData.status === "Done" || obj.availableStok === 0} 
                  max={obj.availableStok}
                  min={1}
                  onChange={(value) => {
                    reassignSize(obj.id, 'piece', value);
                  }}
                />
              </Form.Item>
            </Space>
          ))
        }
        </Space>
        <Space
          align='end'
          style={{ flexBasis: '100%', flexDirection: 'column-reverse', width: '100%', marginTop: '28px', }}
        >
          <Form.Item>
            <Button htmlType="button" size="large" style={{marginRight: '8px'}} 
            onClick={() => {
              setIsOpenModalGambarDetail(false);
              formGambarDetail.resetFields();
            }}> 
              Close
            </Button>
            {
              selectedData.status !== "Done" &&
              <Button type="primary" htmlType="button" size="large" style={{marginRight: '8px'}}
                onClick={submitGambarDetail}
              > 
                Simpan
              </Button>
            }
          </Form.Item>
        </Space>

      </Modal>
    </Context.Provider>
  );
};

const SalesInvoice = () => {
  return (
    <MasterLayout
      pageName={"Sales Invoice"}
      activeMenu={"sales-invoice"} 
      activeParent="sales-invoice"
      content={Content()}
    />
  )
};

export default SalesInvoice;