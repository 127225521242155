import { CallRestApiSecure } from '../../libs/Api';

export const getJenisPakaianChart = () => async () => {
  const BASE_URL = process.env.REACT_APP_API_URL;

  try {
    const response = await CallRestApiSecure('get', BASE_URL, 'v1/dashboard/jenis-pakaian', {}, {}, {}, 'application/json');
    return JSON.parse(response);
  } catch (err) {
    return JSON.parse(err.response.data);
  }
};

export const getPurchaseOrderChart = () => async () => {
  const BASE_URL = process.env.REACT_APP_API_URL;

  try {
    const response = await CallRestApiSecure('get', BASE_URL, 'v1/dashboard/purchase-order', {}, {}, {}, 'application/json');
    return JSON.parse(response);
  } catch (err) {
    return JSON.parse(err.response.data);
  }
};